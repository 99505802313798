import { observer } from "mobx-react-lite";
import React from "react";
import useStores from "../hooks";
import { asset } from "../util/assets";
import { Reference } from "./Reference";
import { ResultOverview } from "./ResultOverview";
import { SectionOverview } from "./SectionOverview";


export const Sidebar = observer(() => {
  const store = useStores();

  return (
    <div className="flex flex-col flex-shrink-0 space-y-2 divide-y-2">
      <div>
        {store.isInResultMode && (
          <div>
            <button
              onClick={() => {
                return store.setIsInResultMode(false);
              }}
              className="flex flex-row mt-2 md:mt-4 left-0 md:left-80 items-center bg-green-600 hover:shadow-md hover:bg-green-700 text-white font-semibold py-4 px-4 rounded-r-xl focus:outline-none z-10"
            >
              <img
                src={asset(`icons/icon-next.png`)}
                alt="The Section Icon"
                className="max-h-6 mr-4"
              ></img>{" "}
              <span>Zurück zur Berechnung</span>
            </button>
          </div>
        )}
        <div className="px-4 p-2 md:py-0 mt-2">
          <ResultOverview></ResultOverview>
        </div>
      </div>
      <div className="overflow-y-auto flex flex-col pt-4 flex-grow md:w-80 w-full bg-white md:rounded-l-md flex-grow">
        <div className="block flex-row md:flex-col px-4">
          {Object.keys(store.data).map((key: string) => {
            return <SectionOverview sectionKey={key}></SectionOverview>;
          })}
        </div>
      </div>
      <div className="hidden md:block">
        <Reference></Reference>
      </div>
    </div>
  );
});
