import { DataModel, Hints, ValueGroup, ValueModel } from "../types";

export const hints: Hints = {
  nutrition: [
    {
      title: "Wie ernähren Sie sich?",
      hints: [
        {
          text: "Aus Umweltsicht lohnt es sich auf tierische Produkte zu verzichten: mit einer veganen Ernährung sparen Sie durchschnittlich ca. acht Economy-Class-Flüge zwischen London und Berlin ein, das sind ca. 670 kg CO2. Wow, nicht schlecht!",
        },
        {
          text: "Achten Sie weiterhin bei Ihrem Einkauf auf saisonale und regionale Produkte und vermeiden Sie unnötigen Verpackungsmüll.",
        },
        {
          text: "Checken Sie doch mal die Unverpackt-, Bio- oder Hofläden um die Ecke und entdecken Sie neue Produkte.",
        },
        {
          text: "Regionale Wochenmärkte bieten darüber hinaus eine große Auswahl an saisonalen Produkten. Hin gehen und beraten lassen, lohnt sich auf jeden Fall!",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.nutritionMode as ValueModel).value === 0,
    },
    {
      title: "Wie ernähren Sie sich?",
      hints: [
        {
          text: "Der Umwelt helfen Sie mit Ihrer vegetarischen Ernährung: Im Durchschnitt retten Sie 164,25 Bäume, sparen 588.015 Liter Wasser, verhindern 1.095 Kilogramm CO2-Emissionen und bewahren 7.525 Kilogramm Nahrung vor der Verschwendung im Jahr. Ein Lob auf Sie!|",
        },
        {
          text: "Fleischersatzprodukte? Gerne ja! Alle? Nein! Achten Sie auf Bio-zertifizierte Produkte, diese entlasten die Umwelt und schonen sie nachhaltig!|",
        },
        {
          text: "Checken Sie doch mal die Unverpackt-, Bio- oder Hofläden um die Ecke und entdecken Sie neue Produkte|",
        },
        {
          text: "Regionale Wochenmärkte bieten darüber hinaus eine große Auswahl an saisonalen Produkten. Hin gehen und beraten lassen, lohnt sich auf jeden Fall!|",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.nutritionMode as ValueModel).value === 1,
    },
    {
      title: "Wie ernähren Sie sich?",
      hints: [
        {
          text: "Ganz ohne Fleisch geht’s nicht, oder? Schon ein Mal weniger in der Woche Fleisch essen spart bei einer 4-köpfigen Familie 700kg CO2 ein! Das wäre doch was?!",
        },
        {
          text: "Wie viel Fleisch ist denn überhaupt genug? Die Empfehlung lautet circa 14 Gramm rotes Fleisch und 29 Gramm Huhn pro Tag, zusammengenommen ergibt das circa 300 Gramm Fleisch pro Woche. Kommen Sie damit zurecht?",
        },
        {
          text: "Essen Sie doch lieber mal ein Schnitzel weniger, anstatt Plastikfolien zu zählen.",
        },
        {
          text: "Fleischersatzprodukte? Gerne ja! Alle? Nein! Achten Sie auf Bio-zertifizierte Produkte, diese entlasten die Umwelt!",
        },
        {
          text: "Checken Sie doch mal die Unverpackt-, Bio- oder Hofläden um die Ecke und entdecken Sie neue Produkte",
        },
        {
          text: "Regionale Wochenmärkte bieten darüber hinaus eine große Auswahl an saisonalen Produkten. Hin gehen und beraten lassen, lohnt sich auf jeden Fall!",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.nutritionMode as ValueModel).value === 2,
    },
    {
      title: "Wie ernähren Sie sich?",
      hints: [
        {
          text: "700 Kilogramm CO2: so viel kann eine vierköpfige Familie vermeiden, wenn sie einmal in der Woche auf ein Kilogramm Fleisch verzichtet.",
        },
        {
          text: "Versuchen Sie seltener und vor allem bewusster Fleisch zu essen.",
        },
        {
          text: "Testen Sie doch mal vegetarische Gerichte. Geht ganz einfach!",
        },
        {
          text: "Wie viel Fleisch ist denn überhaupt genug? Die Empfehlung lautet circa 14 Gramm rotes Fleisch und 29 Gramm Huhn pro Tag, zusammengenommen ergibt das circa 300 Gramm Fleisch pro Woche. Kommen Sie damit zurecht?",
        },
        {
          text: "Essen Sie doch lieber ein Schnitzel weniger, anstatt Plastikfolien zu zählen",
        },
        {
          text: "Planen Sie Ihren Einkauf und informieren Sie sich über regionales Bio-Fleisch. Gibt es evtl. einen Bauern um die Ecke, der etwas anbietet?",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.nutritionMode as ValueModel).value === 3,
    },
    {
      title: "Wie ernähren Sie sich?",
      hints: [
        {
          text: "Massentierhaltung ist für 15 Prozent aller Klimagasemissionen verantwortlich. Das ist mehr als alle Flugzeuge, Autos und Züge zusammen verursachen. Zudem gehen 70 Prozent des weltweiten Frischwasserverbrauchs für die Fleischproduktion drauf. Muss das sein?",
        },
        {
          text: "Wie viel Fleisch ist denn überhaupt genug? Die Empfehlung lautet circa 14 Gramm rotes Fleisch und 29 Gramm Huhn pro Tag, zusammengenommen ergibt das circa 300 Gramm Fleisch pro Woche. Kommen Sie damit zurecht?",
        },
        {
          text: "Essen Sie lieber ein Schnitzel weniger, anstatt Plastikfolien zu zählen",
        },
        {
          text: "Versuchen Sie seltener und vor allem bewusster Fleisch zu essen",
        },
        { text: "Testen Sie doch mal vegetarische Gerichte" },
        {
          text: "Planen Sie Ihren Einkauf und informieren Sie sich über regionales Bio-Fleisch. Gibt es evtl. einen Bauern um die Ecke, der etwas anbietet?",
        },
        {
          text: "Checken Sie doch mal die Unverpackt-, Bio- oder Hofläden und entdecken Sie neue Produkte",
        },
        {
          text: "Regionale Wochenmärkte bieten darüber hinaus eine große Auswahl an saisonalen Produkten. Hin gehen und beraten lassen",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.nutritionMode as ValueModel).value === 4,
    },
    {
      title: "regional & saisonal",
      hints: [
        {
          text: "Keine Lust auf frische Produkte? Regionale Produkte landen direkt im Handel oder in Ihrer Nähe auf Märkten und müssen nicht erst eine Weltreise machen.",
        },
        {
          text: "Saisonale Freilandtomaten aus der Region verursachen 35 g CO2-Emmissionen pro Kilo Tomaten. Tomaten aus Bio-Anbau im Treibhaus hingegen 9,2 kg pro Kilo Tomaten. Das muss nicht sein, oder?",
        },
        {
          text: "Nicht nur die Art des Anbaus selbst, sondern auch der Transport und die Frage nach dem Gewächshaus zählen zur Ökobilanz. Und hier können regionale Produkte ganz klar Punkten",
        },
        {
          text: "Checken Sie den Saison-Kalender! Hier erfahren Sie, welches Obst und Gemüse gerade Saison hat. So vermeiden Sie unnötige CO2 Emissionen!",
        },
        {
          text: "Also: ab auf den nächsten Markt oder in den Hofladen, neues Gemüse und Obst kennen lernen.",
        },
        {
          text: "Schon mal Gemüsekisten vom Bauern um die Ecke ausprobiert? Diese sind nicht nur frisch, sondern bieten auch leckere Rezeptideen!",
        },
        {
          text: "Und mal ehrlich: Erdbeeren im Winter schmecken nicht nach Erdbeeren, eher nach Wasser mit Kernchen...",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.regionalSaisonal as ValueModel).value === 0,
    },
    {
      title: "regional & saisonal",
      hints: [
        {
          text: "Keine Lust auf frische Produkte? Regionale Produkte landen direkt im Handel oder in Ihrer Nähe auf Märkten und müssen nicht erst eine Weltreise machen.",
        },
        {
          text: "Saisonale Freilandtomaten aus der Region verursachen 35 g CO2-Emmissionen pro Kilo Tomaten. Tomaten aus Bio-Anbau im Treibhaus hingegen 9,2 kg pro Kilo Tomaten. Das muss nicht sein, oder?",
        },
        {
          text: "Nicht nur die Art des Anbaus selbst, sondern auch der Transport und die Frage nach dem Gewächshaus zählen zur Ökobilanz. Und hier können regionale Produkte ganz klar Punkten",
        },
        {
          text: "Also: ab auf den nächsten Markt oder in den Hofladen, neues Gemüse und Obst kennen lernen.",
        },
        {
          text: "Schon mal Gemüsekisten vom Bauern um die Ecke ausprobiert? Diese sind nicht nur frisch, sondern bieten auch leckere Rezeptideen!",
        },
        {
          text: "Und mal ehrlich: Erdbeeren im Winter schmecken nicht nach Erdbeeren, sondern eher nach Wasser mit Kernchen...",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.regionalSaisonal as ValueModel).value === 1,
    },
    {
      title: "regional & saisonal",
      hints: [
        {
          text: "Sie sind auf dem richtigen Weg! Regionale und saisonale Produkte helfen nicht nur der Umwelt, sondern schmecken auch!",
        },
        {
          text: "Saisonale Freilandtomaten aus der Region verursachen 35 g CO2-Emmissionen pro Kilo Tomaten. Tomaten aus Bio-Anbau im Treibhaus hingegen 9,2 kg pro Kilo Tomaten. Das muss nicht sein, oder?",
        },
        {
          text: "Nicht nur die Art des Anbaus selbst, sondern auch der Transport und die Frage nach dem Gewächshaus zählen zur Ökobilanz. Und hier können regionale Produkte ganz klar Punkten: und schmecken zudem besser!",
        },
        {
          text: "Saisonal geht immer! Entdecken Sie zu jeder Jahreszeit saisonale Produkte!",
        },
        {
          text: "Ab auf den nächsten Markt oder in den Hofladen, neues Gemüse und Obst kennen lernen.",
        },
        {
          text: "Schon mal Gemüsekisten vom Bauern um die Ecke ausprobiert? Diese sind nicht nur frisch, sondern bieten auch leckere Rezeptideen!",
        },
        {
          text: "Und sind wir mal ehrlich: machen die Einkäufe auf den Märkten nicht auch Spaß ☺",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.regionalSaisonal as ValueModel).value === 2,
    },
    {
      title: "regional & saisonal",
      hints: [
        {
          text: "Super, mit Ihrer Einstellung helfen Sie, lange Transportwege und Kerosinausstöße zu verhindern.",
        },
        {
          text: "Saisonale Freilandtomaten aus der Region verursachen 35 g CO2-Emmissionen pro Kilo Tomaten. Tomaten aus Bio-Anbau im Treibhaus hingegen 9,2 kg pro Kilo Tomaten. Das muss nicht sein, oder?",
        },
        {
          text: "Nicht nur die Art des Anbaus selbst, sondern auch der Transport und die Frage nach dem Gewächshaus zählen zur Ökobilanz. Und hier können regionale Produkte ganz klar Punkten: und schmecken zudem besser!",
        },
        {
          text: "Saisonal geht immer! Entdecken Sie zu jeder Jahreszeit saisonale Produkte!",
        },
        {
          text: "Ab auf den nächsten Markt oder in den Hofladen, neues Gemüse und Obst kennen lernen.",
        },
        {
          text: "Schon mal Gemüsekisten vom Bauern um die Ecke ausprobiert? Diese sind nicht nur frisch, sondern bieten auch leckere Rezeptideen!",
        },
        {
          text: "Und sind wir mal ehrlich: machen die Einkäufe auf den Märkten nicht auch Spaß ☺",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.regionalSaisonal as ValueModel).value === 3,
    },
    {
      title: "Bio",
      hints: [
        {
          text: "Warum denn nur selten? Mit BIO Produkten machen Sie nichts falsch. Probieren Sie doch mal verschiedene Artikel aus.",
        },
        {
          text: "Mit Bio Produkten sichern das Tierwohl, vermeiden unnötige Pestizide und schützen Böden sowie Grundwasser!",
        },
        {
          text: "BIO Lebensmittel führen zu einem bewussteren Umgang mit Lebensmitteln, das ist schon viel Wert!",
        },
        {
          text: "UND: Bauernhöfe und Unverpackt Läden bieten eine breite Auswahl an zertifizierten Produkten an",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.bio as ValueModel).value === 0,
    },
    {
      title: "Bio",
      hints: [
        {
          text: "Ihnen sind BIO Produkte kein Fremdwort, das ist schon viel Wert. Testen Sie doch vermehrt Produkte aus BIO Anbau und lassen Sie sich überzeugen.",
        },
        {
          text: "Mit Bio Produkten sichern das Tierwohl, vermeiden unnötige Pestizide und schützen Böden sowie Grundwasser!",
        },
        {
          text: "BIO Lebensmittel führen zu einem bewussteren Umgang mit Lebensmitteln, das ist schon viel Wert!",
        },
        {
          text: "UND: Bauernhöfe und Unverpackt Läden bieten eine breite Auswahl an zertifizierten Produkten an",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.bio as ValueModel).value === 1,
    },
    {
      title: "Bio",
      hints: [
        {
          text: "Sie sind auf einem sehr guten Weg! Achten Sie doch bei Ihrem nächsten Einkauf bewusst auf BIO Zertifikate und machen Sie aus dem MEISTENS ein IMMER",
        },
        {
          text: "Mit Bio Produkten sichern das Tierwohl, vermeiden unnötige Pestizide und schützen Böden sowie Grundwasser!",
        },
        {
          text: "BIO Lebensmittel führen zu einem bewussteren Umgang mit Lebensmitteln, das ist schon viel Wert!",
        },
        {
          text: "UND: Bauernhöfe und Unverpackt Läden bieten eine breite Auswahl an zertifizierten Produkten an",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.bio as ValueModel).value === 2,
    },
    {
      title: "Bio",
      hints: [
        {
          text: "Sehr gut, Sie haben unseren Respekt! Wenn möglich nach Bio Produkten zu greifen zeigt, wie sehr Ihnen das Thema am Herzen liegt!",
        },
        {
          text: "Mit Bio Produkten sichern das Tierwohl, vermeiden unnötige Pestizide und schützen Böden sowie Grundwasser!",
        },
        {
          text: "Kaufen Sie direkt beim Erzeuger ein, so sparen Sie sich die Gebühr für den Zwischenhändler.",
        },
        {
          text: "Überlegen Sie sich doch auch mal, Mitglied in Ihrem Bio-Supermarkt zu werden",
        },
        {
          text: "Einige Lebensmittel sind in größeren Verpackungen billiger. Daher. Einen großen Sack Möhren kaufen und ab damit in den kühlen Keller.",
        },
        {
          text: "BIO Lebensmittel führen zu einem bewussteren Umgang mit Lebensmitteln, das ist schon viel Wert!",
        },
        {
          text: "UND: Bauernhöfe und Unverpackt Läden bieten eine breite Auswahl an zertifizierten Produkten an",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.bio as ValueModel).value === 3,
    },
    {
      title: "Aktivitätslevel",
      hints: [
        { text: "Mangelnde Bewegung gleich meinen CO2 Ausstoß aus? Jein!" },
        {
          text: "Einerseits – so ist zumindest die Rechnung – ist Ihr Bedarf an Lebensmitteln geringer und somit auch der CO2 Ausstoß.",
        },
        {
          text: "Andererseits muss sich das auch in Ihrem Kauf widerspiegeln: Kaufen Sie Regional und Saisonal? Achten Sie auf Tierwohl und kurze Transportwege?",
        },
        { text: "Und viel wichtiger: Bewegung hält gesund!" },
        {
          text: "Und warum eigentlich nicht mehr Fahrrad fahren? Spart auch CO2 und fördert Ihre Gesundheit.",
        },
        {
          text: "Oder laufen Sie zu den nächsten öffentlichen Transportmitteln, das hilft gleich doppelt!",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.fitness as ValueModel).value === 0,
    },
    {
      title: "Aktivitätslevel",
      hints: [
        { text: "Mangelnde Bewegung gleich meinen CO2 Ausstoß aus? Jein!" },
        {
          text: "Einerseits – so ist zumindest die Rechnung – ist Ihr Bedarf an Lebensmitteln geringer und somit auch der CO2 Ausstoß.",
        },
        {
          text: "Andererseits muss sich das auch in Ihrem Kauf widerspiegeln: Kaufen Sie Regional und Saisonal? Achten Sie auf Tierwohl und kurze Transportweg?",
        },
        { text: "Und viel wichtiger: Bewegung hält gesund!" },
        {
          text: "Und warum eigentlich nicht mehr Fahrrad fahren? Spart auch CO2 und fördert Ihre Gesundheit.",
        },
        {
          text: "Oder laufen Sie zu den nächsten öffentlichen Transportmitteln, das hilft gleich doppelt!",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.fitness as ValueModel).value === 1,
    },
    {
      title: "Aktivitätslevel",
      hints: [
        {
          text: "Bewegung ist gesund und verlängert das Leben - und somit auch die CO2 Verbräuche und Ihren Kalorienbedarf. Aber keine Sorge, ein gesunder Körper spart so manche Autofahrt zum Arzt, Sie sind also quitt ☺",
        },
        {
          text: "Achten Sie bei Ihren Aktivitäten dennoch auf Nachhaltigkeit: Müssen Shakes in Plastikflaschen sein? Die Milch vom Discounter? Muss Fleisch immer die Proteinquelle sein?",
        },
        {
          text: "Testen Sie sich doch mal veganen Proteinquellen: Linsen, Quinoa und Tofu sind nicht nur lecker, sondern sind auch tolle Energielieferanten",
        },
        {
          text: "Nutzen Sie Ihren Bewegungsdrang und erkunden Sie doch mal Ihre Umgebung mit dem Fahrrad        ",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.fitness as ValueModel).value === 2,
    },
    {
      title: "Aktivitätslevel",
      hints: [
        {
          text: "Bewegung ist gesund und verlängert das Leben - und somit auch die CO2 Verbräuche und Ihren Kalorienbedarf. Aber keine Sorge, ein gesunder Körper spart so manche Autofahrt zum Arzt, Sie sind also quitt ☺",
        },
        {
          text: "Achten Sie bei Ihren Aktivitäten dennoch auf Nachhaltigkeit: Müssen Shakes in Plastikflaschen sein? Die Milch vom Discounter? Muss Fleisch immer die Proteinquelle sein? ",
        },
        {
          text: "Testen Sie sich doch mal veganen Proteinquellen: Linsen, Quinoa und Tofu sind nicht nur lecker, sondern sind auch tolle Energielieferanten",
        },
        {
          text: "Nutzen Sie Ihren Bewegungsdrang und erkunden Sie doch mal Ihre Umgebung mit dem Fahrrad        ",
        },
      ],
      condition: (data: DataModel) =>
        (data.nutrition.values.fitness as ValueModel).value === 3,
    },
  ],
  living: [
    {
      title: "Jahresverbrauch (Strom)",
      hints: [
        {
          text: "Super, Sie achten auf Ihren Stromverbrauch. Somit tun Sie nicht nur etwas für die Umwelt, sondern werden daher auch durch Stromkosteneinsparungen belohnt. Energiesparen lohnt sich also doppelt.",
        },
        {
          text: "Durchschnittlich gesehen liegt der Pro-Kopf-Verbrauch der Deutschen bei etwa 1.300 Kilowattstunden (kWh) Strom im Jahr. Das entspricht Stromkosten in Höhe von circa 415 Euro.",
        },
        {
          text: "Ruhen Sie sich darauf jedoch nicht aus und prüfen Sie weitere Einsparpotentiale: Vermeiden Sie Stand-By Betrieb und achten Sie bei Haushaltsgeräten auf Energieeffizienz.",
        },
        {
          text: "UND: Checken Sie unsere Partner! Diese bieten energieeffiziente Produkte an! Hin gehen, erkunden und beraten lassen!",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityConsumption as ValueModel).value === 0,
    },
    {
      title: "Jahresverbrauch (Strom)",
      hints: [
        {
          text: "Sie sind auf dem richtigen Weg: Durchschnittlich gesehen liegt der Pro-Kopf-Verbrauch der Deutschen bei etwa 1.300 Kilowattstunden (kWh) Strom im Jahr. Das entspricht Stromkosten in Höhe von circa 415 Euro.",
        },
        { text: "Sie machen schon viel richtig, Danke im Namen der Umwelt!" },
        {
          text: "Ruhen Sie sich darauf jedoch nicht aus und prüfen Sie weitere Einsparpotentiale: Vermeiden Sie Stand-By Betrieb und achten bei Haushaltsgeräten auf Energieeffizienz",
        },
        {
          text: "UND: Checken Sie unsere Partner! Diese bieten energieeffiziente Produkte an! Hin gehen, erkunden und beraten lassen!        ",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityConsumption as ValueModel).value === 1,
    },
    {
      title: "Jahresverbrauch (Strom)",
      hints: [
        {
          text: "Durchschnittlich gesehen liegt der Pro-Kopf-Verbrauch der Deutschen bei etwa 1.300 Kilowattstunden (kWh) Strom im Jahr. Das entspricht Stromkosten in Höhe von circa 415 Euro.",
        },
        {
          text: "Sie sehen, es ist also noch Luft nach oben und die Umwelt und der Geldbeutel freuen sich!",
        },
        {
          text: "Grundsätzlich gilt: Vermeiden Sie Stand-By Betrieb und achten bei Haushaltsgeräten auf Energieeffizienz",
        },
        {
          text: "UND: Checken Sie unsere Partner! Diese bieten energieeffiziente Produkte an! Hin gehen, erkunden und beraten lassen!        ",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityConsumption as ValueModel).value === 2,
    },
    {
      title: "Jahresverbrauch (Strom)",
      hints: [
        {
          text: "Durchschnittlich gesehen liegt der Pro-Kopf-Verbrauch der Deutschen bei etwa 1.300 Kilowattstunden (kWh) Strom im Jahr. Das entspricht Stromkosten in Höhe von circa 415 Euro.",
        },
        {
          text: "Da besteht aber noch Verbesserungspotential! Gehen Sie doch mal die Geräte und Stromquellen in Ihrer Wohnung durch und checken Sie die Energieeffizienz der Geräte. Benötigen Sie evtl. Neue?",
        },
        {
          text: "UND mit dem Abschalten von nicht benutzen Geräten lässt sich schon viel Strom sparen.",
        },
        { text: "Grundsätzlich gilt: Vermeiden Sie Stand-By Betrieb " },
        {
          text: "Achten Sie vermehrt bei Haushaltsgeräten auf Energieeffizienz",
        },
        {
          text: "Für kleine Kochtätigkeiten muss nicht immer der Herd angeschaltet werden. Mit Wasserkocher oder Toaster sparen Sie jede Menge Strom.",
        },
        {
          text: "UND: Checken Sie unsere Partner! Diese bieten energieeffiziente Produkte an! Hin gehen, erkunden und beraten lassen!        ",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityConsumption as ValueModel).value === 3,
    },
    {
      title: "Stromquelle",
      hints: [
        { text: "Super, dass Sie bereits Ökostrom beziehen!" },
        {
          text: "Ökostrom ist einfach wertvoller, da er 15-mal weniger C02 -Emissionen verursacht und manchmal sogar günstiger ist!",
        },
        {
          text: "Dennoch gilt folgendes: gehen Sie bewusst mit Strom um, sparen Sie dadurch Energie und schonen Sie Umwelt und Ihren Geldbeutel",
        },
        {
          text: "ABER: Achten Sie bei Ökostrom auf die Label Grüner Strom und OK Power, diese achten zu 100% auf Nachhaltigkeit und sind bundesweit zertifiziert.",
        },
        {
          text: "Viele Produkte haben einen geringen Stromverbrauch und eine lange Lebensdauer: Lampen, Kühlschränke, Küchengeräte usw. Checken Sie doch unsere Partner, um geeignete Artikel zu finden!        ",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityType as ValueModel).value === 0,
    },
    {
      title: "Stromquelle",
      hints: [
        {
          text: "Der Strommix kann sich zwischen verschiedenen Energieversorgern in einem Land massiv unterscheiden. In Deutschland setzen einige Großkonzerne zu über 60 %, teils sogar über 70 % auf fossile Energieträger, d. h. weitgehend auf Kohle und Erdgas.",
        },
        {
          text: "Dagegen liefern Ökostrom-Anbieter größtenteils Strom aus erneuerbaren Energien, teilweise auch Strom aus schadstoffarmer Kraft-Wärme-Kopplung auf der Basis von Erdgas.",
        },
        {
          text: "In Ihrer Rechnung können Sie die genaue Zusammensetzung Ihres Strommixes kontrollieren.",
        },
        {
          text: "Dabei ist zu sagen: 85% der Treibhausgas-Emissionen in Deutschland gehen auf das Konto von fossilen Brennstoffen (Umweltbundesamt)",
        },
        {
          text: "Vielfach werden diese in weit entfernten Teilen der Erde gewonnen und legen lange Wege zurück – zusätzliche Treibhausgase durch lange Transportwege entstehen",
        },
        {
          text: "Schon mal über einen Wechsel des Stromanbieters nachgedacht? Das kostet Sie keine 15 Minuten – und hilft der Umwelt enorm!",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityType as ValueModel).value === 1,
    },
    {
      title: "Stromquelle",
      hints: [
        {
          text: "85% der Treibhausgas-Emissionen in Deutschland gehen auf das Konto von fossilen Brennstoffen",
        },
        {
          text: "Fossile Brennstoffe wie Gas, Erdöl, Braunkohle und Steinkohle schädigen unsere Umwelt nachhaltig enorm. Durch die Verbrennung der Brennstoffe setzen wir den Kohlenstoff frei, die Pflanzen vor Jahrmillionen aus der Atmosphäre aufgenommen und gespeichert haben. Durch die Verbindung mit Sauerstoff entsteht CO2.",
        },
        {
          text: "Vielfach werden diese in weit entfernten Teilen der Erde gewonnen und legen lange Wege zurück – zusätzliche Treibhausgase durch lange Transportwege entstehen",
        },
        {
          text: "Macht es daher nicht Sinn, den Stromanbieter zu wechseln? Das kostet Sie keine 15 Minuten – und hilft der Umwelt ungemein!",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.electricityType as ValueModel).value === 2,
    },
    // {
    //   title: "Art der Heizung",
    //   hints: [],
    //   condition: (data: DataModel) =>
    //     (data.living.values.heatingType as ValueModel).value === 0,
    // },
    {
      title: "Art der Heizung",
      hints: [
        {
          text: "Die Heizung macht in Deutschland ca. 75% des Energieverbrauchs im Haushalt aus.",
        },
        {
          text: "Erdgas verursacht zwar in der Verbrennung weniger CO2-Emissionen als Erdöl oder Kohle, dennoch ist es ein fossiler Energieträger",
        },
        {
          text: "Bei einem jährlichen Energieverbrauch von 20.000 Kilowattstunden, stößt eine Gasheizung demnach 4.940kg CO2 und 120 Gramm Feinstaub aus.",
        },
        {
          text: "Klimafreundliche Energieträger sind zu empfehlen! Schauen Sie doch mal bei unseren Partnern vorbei, hier finden Sie großartige Angebote für neue, nachhaltige Heizungen",
        },
        {
          text: "Ob Erstberatung, Basis-Check, Gebäude-Check oder Heizcheck – ein Energieberater analysiert den aktuellen Energiebedarf Ihrer Immobilie. Hierbei begutachtet er wichtige Bauteile sowie die Anlagentechnik und empfiehlt Ihnen Sanierungsmaßnahmen unter wirtschaftlichen und ökologischen Aspekten. Gut zu wissen: Energieberatungen für Eigentümer werden großzügig gefördert",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingType as ValueModel).value === 0,
    },
    {
      title: "Art der Heizung",
      hints: [
        {
          text: "Die Heizung macht in Deutschland ca. 75% des Energieverbrauchs im Haushalt aus.",
        },
        {
          text: "Ölheizungen sind in deutschen Haushalten immer noch zu ca. 25% verbreitet. Viele davon sind mittlerweile stark veraltet.",
        },
        {
          text: "Die CO2-Bilanz von Heizöl ist dabei noch schlechter als die von Erdgas. Laut Umweltbundesamt verursacht ein Heizöl-Kessel pro Kilowattstunde 318 Gramm. Bei einer Wohnfläche von 120 m2, für die man in der Regel ca. 15.000 Kilowattstunden Heizenergie benötigt, verursacht der Haushalt der Öl-Haushalt 4,7 Tonnen.",
        },
        {
          text: "Mittlerweile bieten viele Unternehmen nachhaltige Heizungen an. Eine Beratung ist schnell ausgemacht und kostenlos!",
        },
        {
          text: "Ob Erstberatung, Basis-Check, Gebäude-Check oder Heizcheck – ein Energieberater analysiert den aktuellen Energiebedarf Ihrer Immobilie. Hierbei begutachtet er wichtige Bauteile sowie die Anlagentechnik und empfiehlt Ihnen Sanierungsmaßnahmen unter wirtschaftlichen und ökologischen Aspekten. Gut zu wissen: Energieberatungen für Eigentümer werden großzügig gefördert",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingType as ValueModel).value === 1,
    },
    {
      title: "Art der Heizung",
      hints: [
        {
          text: "Die Heizung macht in Deutschland ca. 75% des Energieverbrauchs im Haushalt aus.",
        },
        {
          text: "Je nach Art der Heizung, entstehen hier mehr oder weniger CO2.",
        },
        {
          text: "Es gilt aber: Klimafreundliche Energieträger sind zu empfehlen!",
        },
        {
          text: "Mittlerweile bieten viele Unternehmen nachhaltige Heizungen an. Eine Beratung ist schnell ausgemacht und kostenlos!",
        },
        {
          text: "Ob Erstberatung, Basis-Check, Gebäude-Check oder Heizcheck – ein Energieberater analysiert den aktuellen Energiebedarf Ihrer Immobilie. Hierbei begutachtet er wichtige Bauteile sowie die Anlagentechnik und empfiehlt Ihnen Sanierungsmaßnahmen unter wirtschaftlichen und ökologischen Aspekten. Gut zu wissen: Energieberatungen für Eigentümer werden großzügig gefördert",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingType as ValueModel).value === 2,
    },
    {
      title: "Art der Heizung",
      hints: [
        {
          text: "Die Heizung macht in Deutschland ca. 75% des Energieverbrauchs im Haushalt aus.",
        },
        {
          text: "Bei einer Stromheizung kommt es hauptsächlich darauf an, ob mit einer Wärmepumpe oder mit Nachtspeicherofen geheizt wird",
        },
        {
          text: "Mit der Nutzung einer Wärmepumpe verdoppelt sich nicht nur der Stromverbrauch, sondern auch der CO2-Ausstoß, sofern die Wärmepumpe mit Kohlestrom betrieben wird. Ein CO2-Posten, den man locker vermeiden kann. Der Wechsel zu Ökostrom geht zu einfach, sicher und schnell, um dieses Klimaschutzpotenzial zu verschenken. ",
        },
        {
          text: "Eine Nachtspeicherheizung verursacht mehr als doppelt so viele CO2-Emissionen wie eine Gas- oder Ölheizung, wenn der Haushalt Kohlestrom nutzt. Laut Umweltbundesamt verursachte eine Kilowattstunde Strom aus konventioneller Erzeugung im Schnitt etwa 489 Gramm CO2. Da kommt richtig was zusammen!",
        },
        {
          text: "Wer mit Strom klimaneutral heizen möchte, muss nicht gleich das Heizsystem auszutauschen, sondern kann einfach nur den Stromanbieter wechseln. ALSO? Alles eine Frage der Entscheidung für den Anbieter!",
        },
        {
          text: "Mittlerweile bieten viele Unternehmen nachhaltige Heizungen an. Eine Beratung ist schnell ausgemacht und kostenlos!",
        },
        {
          text: "UND: Checken Sie Ihre Tarife! Hier kann man einiges an CO2 einsparen bzw. Geld sparen!",
        },
        {
          text: "Ob Erstberatung, Basis-Check, Gebäude-Check oder Heizcheck – ein Energieberater analysiert den aktuellen Energiebedarf Ihrer Immobilie. Hierbei begutachtet er wichtige Bauteile sowie die Anlagentechnik und empfiehlt Ihnen Sanierungsmaßnahmen unter wirtschaftlichen und ökologischen Aspekten. Gut zu wissen: Energieberatungen für Eigentümer werden großzügig gefördert",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingType as ValueModel).value === 3,
    },
    {
      title: "Heizvorlieben",
      hints: [
        {
          text: "Sie sind wohl in der Antarktis geboren? Mit Ihrem Heizverhalten schonen Sie die Umwelt nachhaltig. Überragend!",
        },
        {
          text: "Erwiesenermaßen schläft man in kühlen Räumen besser, und wenn man nicht gerade den ganzen Tag zuhause ist, spürt man die kühleren Temperaturen ohnehin nicht.",
        },
        { text: "UND: Nur ein Grad weniger spart schon 6% an Energie." },
        {
          text: "Heizkörper freihalten und Türen schließen! Das hält nicht nur die Wärme in den Räumen, sondern schont die Umwelt auch nachhaltig",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingPreferences as ValueModel).value === 0,
    },
    {
      title: "Heizvorlieben",
      hints: [
        {
          text: "Sie haben wohl das Glück, zu den Kaltblütlern unter den Menschen zu gehören. Das freut das Klima und Ihren Geldbeutel auch!",
        },
        {
          text: "Erwiesenermaßen schläft man in kühlen Räumen besser, und wenn man nicht gerade den ganzen Tag zuhause ist, spürt man die kühleren Temperaturen ohnehin nicht.",
        },
        { text: "UND: Nur ein Grad weniger spart schon 6% an Energie." },
        {
          text: "Heizkörper freihalten und Türen schließen! Das hält nicht nur die Wärme in den Räumen, sondern schont die Umwelt auch nachhaltig",
        },
        {
          text: "Wählen Sie Temperaturen, die zur Raumnutzung passen und drehen die Heizkörper zu Nichtnutzungszeiten etwas herunter.",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingPreferences as ValueModel).value === 1,
    },
    {
      title: "Heizvorlieben",
      hints: [
        {
          text: "Mit Ihrem Heizverhalten liegen Sie im Durchschnitt, aber besser geht es natürlich immer ☺",
        },
        {
          text: "Wählen Sie Temperaturen, die zur Raumnutzung passen und drehen die Heizkörper zu Nichtnutzungszeiten etwas herunter.",
        },
        {
          text: "Mit jedem Grad Celsius, um das die Durchschnittstemperatur fällt, sparen sie im Bestand rund 6% an Energie ein. Mit dem Verbrauch sinken auch die CO2- und Feinstaubemissionen.",
        },
        {
          text: "Heizkörper freihalten und Türen schließen! Das hält nicht nur die Wärme in den Räumen, sondern schont die Umwelt auch nachhaltig",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingPreferences as ValueModel).value === 2,
    },
    {
      title: "Heizvorlieben",
      hints: [
        {
          text: "Tagtäglich auf 23-25 Grad aufzuheizen ist nicht unbedingt notwendig und im Übrigen auch nicht gerade gut für den Geldbeutel.",
        },
        {
          text: "Erwiesenermaßen schläft man in kühlen Räumen besser, und wenn man nicht gerade den ganzen Tag zuhause ist, spürt man die kühleren Temperaturen ohnehin nicht. ",
        },
        {
          text: "Nur ein Grad weniger spart schon 6% an Energie. Wer den Thermostat von 24 auf 20 Grad runterdreht, hat es immer noch angenehm UND spart aber rund 25% an Heizenergie. Das wirkt sich positiv auf die Umwelt aus und das Konto freut sich zusätzlich.",
        },
        {
          text: "Heizkörper freihalten und Türen schließen! Das hält nicht nur die Wärme in den Räumen, sondern schont die Umwelt auch nachhaltig",
        },
        {
          text: "Wählen Sie Temperaturen, die zur Raumnutzung passen und drehen die Heizkörper zu Nichtnutzungszeiten etwas herunter. ",
        },
      ],
      condition: (data: DataModel) =>
        (data.living.values.heatingPreferences as ValueModel).value === 3,
    },
  ],
  mobility: [
    {
      title: "Auto",
      hints: [
        { text: "Wow! Super, dass Sie hier die Umwelt komplett schonen!" },
        {
          text: "Demnach: halten Sie ihr Fahrrad gut in Schuss und nutzen Sie die öffentlichen Verkehrsmittel weiterhin.",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).value === 0,
    },
    {
      title: "Auto",
      hints: [
        {
          text: "Respekt! Richtig gut! Das Auto so wenig wie möglich zu benutzen ist eine tolle Leistung!",
        },
        {
          text: "ABER: Ein Mittelklassewagen schluckt direkt nach dem Start rund 30 bis 40 Liter auf 100 Kilometer bevor die Betriebstemperatur erreicht ist. ",
        },
        {
          text: "Und je mehr Kraftstoff verbraucht wird, desto mehr Treibhausgase werden ausgestoßen.",
        },
        {
          text: "DAHER: Lassen Sie das Auto öfter mal stehen und nutzen Sie für kurze Wege das Fahrrad oder gehen Sie zu Fuß – vor allem im Stadtverkehr!",
        },
        {
          text: "In der Regel ist der CO2 Ausstoß im Stadtverkehr und auf der Autobahn am höchsten.",
        },
        {
          text: "Versuchen Sie es weiterhin so wenig wie möglich mit dem Auto zu fahren. Informieren Sie sich über die Angebote der öffentlichen Verkehrsmittel. Sind die Wege nicht weit, laufen Sie gerne mal zu Fuß oder für etwas weitere Strecken lohnt sich der Umstieg auf das Fahrrad.",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).value <= 100 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).value > 0,
    },
    {
      title: "Auto",
      hints: [
        {
          text: "Hier liegen Sie im Durchschnitt! Ihre gefahrenen km/Woche halten sich noch in Grenzen, super!",
        },
        {
          text: "Schnelles Schalten und niedertouriges, gleichmäßiges Fahren ist umweltfreundlich und spart Sprit.",
        },
        {
          text: "Und: schalten Sie doch Heizung und Klimaanlage nur ein, wenn Sie es wirklich brauchen",
        },
        {
          text: "Entfernen Sie auch unnötigen Ballast, wie Gepäck- oder Dachträger",
        },
        {
          text: "Dennoch: Haben Sie schon mal über Teilen bzw. Fahrgemeinschaften nachgedacht? Zusammen mit einer Kollegin oder einem Kollegen fahren?",
        },
        {
          text: "Oder mit CAR SHARING ganz auf das eigene Auto verzichten und Geld sparen",
        },
        {
          text: "UND: Neuwagen können schon innerhalb von kurzer Zeit Ihren CO2 Ausstoß verbessern",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).value <= 200 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).value > 100,
    },
    {
      title: "Auto",
      hints: [
        {
          text: "Rund 80% der Treibhausgasemissionen eines Autos hängen direkt mit seinem Spritverbrauch zusammen. Der Spritverbrauch wiederum hängt – neben dem spezifischen Spritverbrauch des Autos – in hohem Maße vom Fahrstil ab.",
        },
        {
          text: "Die Einsparungen durch einen sparsamen Fahrstil betragen mehrere hundert Euro pro Jahr. Bei durchschnittlichen Werten sind das über 400 Euro und 700 kg weniger CO2",
        },
        {
          text: "DAHER: Schnelles Schalten und niedertouriges, gleichmäßiges Fahren ist umweltfreundlich und spart Sprit",
        },
        { text: "UND: Motor abschalten lohnt sich ab 30 Sekunden" },
        {
          text: "In der Regel ist der CO2 Ausstoß im Stadtverkehr und auf der Autobahn am höchsten.",
        },
        {
          text: "Entfernen Sie auch unnötigen Ballast, wie Gepäck- oder Dachträger",
        },
        {
          text: "Dennoch, schon mal Teilen nachgedacht? Zusammen mit einer Kollegin oder einem Kollegen fahren?",
        },
        {
          text: "Oder mit CAR SHARING ganz auf das eigene Auto verzichten und Geld sparen.",
        },
        {
          text: "UND: Neuwagen können schon innerhalb von kurzer Zeit Ihren CO2 Ausstoß verbessern",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).value > 200,
    },
    {
      title: "Auto",
      hints: [
        {
          text: "Elektrisch fahren ist schon mal super und spart auf den ersten Blick viel CO2 ein",
        },
        {
          text: "DOCH: Die Umweltbilanz von Elektroautos hängt von der Stromerzeugung bzw. Stromquelle ab",
        },
        {
          text: "Heißt: Checken Sie Ihren Stromversorger und informieren Sie sich über Ökostrom",
        },
      ],
      condition: (data: DataModel) =>
        !!(
          (data.mobility.values.transport as ValueGroup).values
            .car as ValueModel
        ).isElectric,
    },
    {
      title: "Carsharing",
      hints: [
        { text: "Komplett auf das Auto zu verzichten ist sehr gut!" },
        {
          text: "Car-Sharing ist für jeden: Einzelpersonen, Familien, Fahrgemeinschaften, öffentliche Verwaltungen sowie private und öffentliche Unternehmen",
        },
        {
          text: "Car-Sharing entlastet die Umwelt, schont den Geldbeutel, spart Ressourcen und leistet einen Beitrag zur Energieeinsparung im Verkehr.",
        },
        {
          text: "Daher falls es mal die Überlegung gibt: Car-Sharing ist eine tolle Option!",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).value === 0,
    },
    {
      title: "Carsharing",
      hints: [
        {
          text: "Super, dass Sie sich bei Kurzstrecken für das Sharing von Autos entscheiden, damit leisten Sie schon sehr viel",
        },
        {
          text: "Car-Sharing entlastet die Umwelt, schont den Geldbeutel, spart Ressourcen und leistet einen Beitrag zur Energieeinsparung im Verkehr.",
        },
        {
          text: "ABER: Brauchen Sie nun wirklich ein Auto. Diese Strecke kann man doch auch mit dem Fahrrad oder den öffentlichen Verkehrsmitteln fahren",
        },
        {
          text: "UND: Gerade bei Kurzstrecken in der Stadt ist die Belastung mit dem Auto am höchsten",
        },
        {
          text: "Car-Sharing spart Geld: Das Verbrauchermagazin Finanztip hat errechnet, dass die jährlichen Carsharing-Kosten bei 5.000 km zwischen rund 900 und 1.500 Euro niedriger liegen als bei einem eigenen Auto",
        },
        {
          text: "DAHER: Überdenken Sie jeden gefahrenen Kilometer mit dem Auto und greifen lieber zu den öffentlichen Verkehrsmitteln und dem Fahrrad",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).value <= 100 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).value > 0,
    },
    {
      title: "Carsharing",
      hints: [
        {
          text: "Super, dass Sie auch bei längeren Strecken zu Car Sharing Angeboten greifen.",
        },
        {
          text: "Car-Sharing entlastet die Umwelt, schont den Geldbeutel, spart Ressourcen und leistet einen Beitrag zur Energieeinsparung im Verkehr.",
        },
        {
          text: "Car-Sharing spart Geld: Das Verbrauchermagazin Finanztip hat errechnet, dass die jährlichen Carsharing-Kosten bei 5.000 km zwischen rund 900 und 1.500 Euro niedriger liegen als bei einem eigenen Auto",
        },
        {
          text: "Denn: ein eigenes Auto steht durchschnittlich 23 Stunden am Tag unbenutzt au der Parkfläche",
        },
        {
          text: "DAHER: Sharing ist super, aber da geht noch mehr! Benutzen Sie doch das nächste Mal öffentliche Verkehrsmittel",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).value <= 200 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).value > 100,
    },
    {
      title: "Carsharing",
      hints: [
        {
          text: "Klasse, dass Sie bei langen Strecken auf Car Sharing Angebote zurückgreifen.",
        },
        {
          text: "Sie profitieren ganz besonders davon und sparen viel Geld: Das Verbrauchermagazin Finanztip hat errechnet, dass die jährlichen Carsharing-Kosten bei 5.000 km zwischen rund 900 und 1.500 Euro niedriger liegen als bei einem eigenen Auto",
        },
        {
          text: "DENNOCH: gerade bei langen Strecken geht nichts über den Zug. Schauen Sie doch mal, ob sich Ihre Strecke nicht damit bewältigen können.",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).value > 200,
    },
    {
      title: "Carsharing",
      hints: [
        {
          text: "Elektrisch fahren ist schon mal super und spart auf den ersten Blick viel CO2 ein",
        },
        {
          text: "DOCH: Die Umweltbilanz von Elektroautos hängt von der Stromerzeugung bzw. Quelle ab",
        },
        {
          text: "Heißt: Checken Sie Ihren Stromversorger und informieren Sie sich über Ökostrom",
        },
      ],
      condition: (data: DataModel) =>
        !!(
          (data.mobility.values.transport as ValueGroup).values
            .carsharing as ValueModel
        ).isElectric,
    },

    {
      title: "Motorrad / Motorroller",
      hints: [
        {
          text: "Sehr gut! Für Kurzstrecken das Auto stehen zu lassen und auf das Motorrad oder den Motorroller zurück zu greifen ist schon mal der richtige Weg",
        },
        {
          text: "Dennoch: bei Kurzstrecken macht das Fahrrad noch am meisten Sinn",
        },
        {
          text: "Wenn Sie hauptsächlich in der Stadt unterwegs sind, können Sie mit dem Motorrad oder Motorroller fast das 1,5-fache an CO2 im Vergleich zum Auto sparen",
        },
        {
          text: "Darüber hinaus: Vermeiden Sie unnötig hochtourige Fahrweise und achten Sie auf korrekten Reifendruck",
        },
        {
          text: "Wer also seine CO2 Bilanz mit dem Motorrad oder dem Roller verbessern will, der setzt sich nicht nur Sonntagsnachmittags auf das Gefährt, sondern jeden Tag, fährt damit zur Arbeit, und auch zum Einkauf",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .motorcycle as ValueModel
        ).value <= 49 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .motorcycle as ValueModel
        ).value > 0,
    },
    {
      title: "Motorrad / Motorroller",
      hints: [
        {
          text: "Für längere Touren das Auto stehen lassen und auf das Motorrad oder den Motorroller zurück greifen macht durchaus Sinn: mit ca. 80 Gramm CO2 pro Kilometer stoßen Sie hier weniger aus, als mit dem Auto ",
        },
        {
          text: "Auf was Sie achten sollten: vermeiden Sie unnötig hochtourige Fahrweise und achten Sie auf korrekten Reifendruck",
        },
        {
          text: "Wer also seine CO2 Bilanz mit dem Motorrad oder dem Roller verbessern will, der setzt sich nicht nur Sonntagsnachmittags auf das Gefährt, sondern jeden Tag, fährt damit zur Arbeit, und auch zum Einkauf",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .motorcycle as ValueModel
        ).value > 50,
    },
    {
      title: "Motorrad / Motorroller",
      hints: [
        {
          text: "Elektrisch fahren ist schon mal super und spart auf den ersten Blick viel CO2 ein",
        },
        {
          text: "DOCH: Die Umweltbilanz von Motorrädern und Rollern hängt von der Stromerzeugung bzw. Quelle ab",
        },
        {
          text: "Heißt: Checken Sie Ihren Stromversorger und informieren Sie sich über Ökostrom",
        },
      ],
      condition: (data: DataModel) =>
        !!(
          (data.mobility.values.transport as ValueGroup).values
            .motorcycle as ValueModel
        ).isElectric,
    },
    {
      title: "Öffentliche Verkehrsmitteln",
      hints: [
        {
          text: "Wie? Gar nicht mit den öffentlichen Verkehrsmitteln unterwegs. Wobei man gerade mit diesen Transportmitteln auf Kurzstrecken gegenüber dem Auto viel CO2 einsparen kann",
        },
        {
          text: "Checken Sie doch mal die Verbindungen in Ihrer Nähe und testen Sie mal eine Fahrt mit den öffentlichen Verkehrsmitteln zur Arbeit",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .publicTransportation as ValueModel
        ).value == 0,
    },
    {
      title: "Öffentliche Verkehrsmitteln",
      hints: [
        {
          text: "Auch bzw. gerade bei Kurzstrecken sind öffentliche Verkehrsmittel kaum weg zu denken und sparen zudem noch viel CO2 ein",
        },
        {
          text: "Mit ca. 80 Gramm pro Kilometer liegen diese weit vor dem Auto.",
        },
        {
          text: "Daher: Auto stehen lassen und zu den öffentlichen Verkehrsmitteln greifen.",
        },
        {
          text: "Noch besser: Fahrten mit dem Fahrrad kombinieren oder komplett auf das Fahrrad umsteigen",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .publicTransportation as ValueModel
        ).value <= 26 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .publicTransportation as ValueModel
        ).value > 1,
    },
    {
      title: "Öffentliche Verkehrsmitteln",
      hints: [
        {
          text: "Super, um so länger die Strecke ist und man das Auto stehen lassen kann, um so mehr CO2 spart man ein.",
        },
        {
          text: "Daher: Auto weiterhin stehen lassen und die öffentlichen Verkehrsmittel ausreichen benutzen",
        },
        {
          text: "Informieren Sie sich doch auch mal, wo in der Region elektrifizierte Verkehrsmittel eingesetzt werden, hier können mehr Co2 Ausstöße vermieden werden.",
        },
        {
          text: "Noch besser: Fahrten mit dem Fahrrad kombinieren oder komplett auf das Fahrrad umsteigen",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .publicTransportation as ValueModel
        ).value > 26,
    },
    {
      title: "Zugfahrten",
      hints: [
        {
          text: "Schön, dass Sie schon bei kürzeren Strecken auf den Zug zurückgreifen.",
        },
        {
          text: "Auf Nahstrecken verbraucht der Zug nur 58 Gramm Treibhausgase pro Kilometer. Das schaffen sonst kaum Transportmittel",
        },
        {
          text: "Dennoch: Die Auslastung macht’s: Die entstehenden Schafstoffe werden auf die Menge an Menschen im Fahrzeug verteilt. Also: je ausgelasteter das Fahrzeug, desto weniger Schadstoffe pro Mensch.",
        },
        {
          text: "Aber: kurze Strecken können zumeist auch gut mit dem Fahrrad bewältigt werden. Überlegen Sie sich das doch mal!",
        },
        {
          text: "UND: nehmen Sie doch Kaffee oder Getränke von daheim mit und sparen Sie dadurch unnötigen Müll",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .train as ValueModel
        ).value <= 25 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .train as ValueModel
        ).value > 1,
    },
    {
      title: "Zugfahrten",
      hints: [
        {
          text: "Gerade bei weiteren Strecken punktet der Zug gegenüber allen anderen Transportmitteln",
        },
        {
          text: "Auf langen Strecken erzeugt der Zug pro Person und Kilometer nur 32 Gramm Treibhausgase. Im Auto sind es mit 147 Gramm fast fünf Mal mehr, beim Inlandsflug sogar 230 Gramm und damit mehr als das Siebenfache.",
        },
        {
          text: "Dennoch: Die Auslastung macht’s: Die entstehenden Schafstoffe werden auf die Menge an Menschen im Fahrzeug verteilt. Also: je ausgelasteter das Fahrzeug, desto weniger Schadstoffe pro Mensch.",
        },
        {
          text: "UND: nehmen Sie doch Kaffee oder Getränke von daheim mit und sparen Sie dadurch unnötigen Müll",
        },
        {
          text: "AUCH: Überlegen Sie doch mal Ihre nächste Reise mit dem Zug anzutreten. Spart CO2, man erlebt tolle Landschaften und entspannt ist es auch noch. Und das Beste: meistens ist der Zug genauso schnell im Vergleich zu anderen Transportmitteln",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .train as ValueModel
        ).value > 25,
    },
    {
      title: "Fahrrad",
      hints: [
        {
          text: " Fahrrad fahren ist das nachhaltigste Transportmittel, neben dem zu Fuß gehen. Warum also nicht mal mit dem Fahrrad fahren? Kein Fahrrad zur Hand, dann leihen Sie sich doch einfach eins aus oder stöbern Sie auf Verkaufsplattformen für Gebrauchtes, da finden Sie sicherlich etwas Passendes.",
        },
        {
          text: " Vor allem: Radfahren fördert nachhaltig die Gesundheit, stärkt das Immunsystem, steigert das Wohlbefinden und wirkt sich positiv auf die Psyche aus.",
        },
        { text: " Und das Beste: es wird eine Menge CO2 eingespart" },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .bicycle as ValueModel
        ).value === 0,
    },
    {
      title: "Fahrrad",
      hints: [
        {
          text: "Super, Sie befinden sich hier im deutschen Durchschnittswert.",
        },
        {
          text: "So viel schon vorab: das Fahrrad ist das nachhaltigste aller Transportmittel, auch wenn es durch die Produktion und die Logistik CO2 ausstößt",
        },
        {
          text: "Vor allem im Stadtverkehr und bei kurzen Strecken kann es punkten",
        },
        {
          text: "Rund 310 Kilogramm CO2-Emissionen im Jahr können wir sparen – wenn wir mit dem Fahrrad zur Arbeit fahren. Und das Beste: In vielen Großstädten ist man mit dem Fahrrad sogar schneller als mit dem Auto.",
        },
        {
          text: "UND: viele Arbeitgeber bieten mittlerweile Dienstfahrräder an. Checken Sie das doch mal ab!",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .bicycle as ValueModel
        ).value <= 49 &&
        (
          (data.mobility.values.transport as ValueGroup).values
            .bicycle as ValueModel
        ).value > 0,
    },
    {
      title: "Fahrrad",
      hints: [
        { text: "Wow, klasse, dass Sie ihr Fahrrad so häufig benutzen" },
        {
          text: "So viel schon vorab: das Fahrrad ist das nachhaltigste aller Transportmittel, auch wenn es durch die Produktion und die Logistik CO2 ausstößt",
        },
        {
          text: "Je länger die zu fahrenden Strecke, desto mehr CO2 wird eingespart",
        },
        {
          text: "Und das Beste: In vielen Großstädten ist man mit dem Fahrrad sogar schneller als mit dem Auto.",
        },
        {
          text: "UND: viele Arbeitgeber bieten mittlerweile Dienstfahrräder an. Checken Sie das doch mal ab!",
        },
        {
          text: "Achten Sie bei der Wartung auf umweltfreundliche Komponenten und kaufen Sie diese in Ihrer Region ein.",
        },
        {
          text: "Und was ist mit einem E-Bike? Noch schneller am Ziel ankommen und längere Distanzen begleichen.",
        },
      ],
      condition: (data: DataModel) =>
        (
          (data.mobility.values.transport as ValueGroup).values
            .bicycle as ValueModel
        ).value > 50,
    },
    {
      title: "Fahrrad",
      hints: [
        {
          text: "E-Bikes sind nicht nur in ihrer Ökobilanz gut, sondern ersetzten auch das Auto.",
        },
        {
          text: "Wenn mit dem Rad ein Auto ersetzt wird, sind die Emissionen nach 165 geradelten statt gefahrenen Kilometern beglichen.",
        },
        {
          text: "Dennoch ist es wichtig darauf zu achten, welchen Strom Sie beziehen, denn dann wird die Herstellung des Akkus noch schneller ausgeglichen",
        },
        {
          text: "UND: selbst eine lange (Urlaubs-) Reise mit dem E-Bike macht total Spaß",
        },
      ],
      condition: (data: DataModel) =>
        !!(
          (data.mobility.values.transport as ValueGroup).values
            .bicycle as ValueModel
        ).isElectric,
    },
    {
      title: "Flugreisen",
      hints: [
        {
          text: "Gar nicht Fliegen ist sehr gut! Die Natur spricht ein großes Lob aus!",
        },
        {
          text: "Denn somit können Sie pro Flug bei Kurzstrecken 750kg CO2 und bei Transatlantikflügen bereits 3,65 Tonnen CO2 einsparen",
        },
      ],
      condition: (data: DataModel) =>
        (data.mobility.values.continentalFlights as ValueModel).value +
          (data.mobility.values.intercontinentalFlights as ValueModel).value ===
        0,
    },
    {
      title: "Flugreisen",
      hints: [
        { text: "Muss der Flug wirklich sein? Gibt es keine Alternative?" },
        {
          text: "Wer mit dem Flugzeug durch die Welt reist, stößt sehr viel CO2 aus. Denn der Flugverkehr sorgt zum einen für direkte CO2-Emissionen, zum anderen für Stickoxide und Wasserdampf in hohen Luftschichten.",
        },
        {
          text: "Ein Interkontinentalflug mehr CO2-Emissionen in die Luft, als ein ganzes Jahr Autofahren in einem Mittelklassewagen. Zwei halbe Tage fliegen versus ein ganzes Jahr Autofahren.",
        },
        {
          text: "Der Einfluss von Flugreisen auf das Klima liegt aktuellen Studien nach bei knapp 4,9 Prozent. CO2 hat, wie auch Wasserdampf, einen wärmenden Effekt: Stickoxide bilden die treibhauswirksamen Gase Ozon und Methan, so das Umweltbundesamt.",
        },
        {
          text: "DAHER: Reisen mit dem Zug, Bus oder mit dem eigenen PKW sind weitaus umweltfreundlicher.",
        },
      ],
      condition: (data: DataModel) =>
        (data.mobility.values.continentalFlights as ValueModel).value +
          (data.mobility.values.intercontinentalFlights as ValueModel).value >
        0,
    },
  ],
  consumption: [
    {
      title: "monatlichen Ausgaben",
      hints: [
        {
          text: "Wow, Sie leben echt das Minimalprinzip! Von Ihnen kann man sich noch etwas Abschauen!",
        },
        {
          text: "Falls Sie dennoch das Shopping-Fieber packt: unsere Partner bieten viele nachhaltige Produkte & Dienstleistungen an. Schauen Sie doch mal rein!",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.expenses as ValueModel).value > 1 &&
        (data.consumption.values.expenses as ValueModel).value <= 259,
    },
    {
      title: "monatlichen Ausgaben",
      hints: [
        {
          text: "Sie geben weniger als der Durchschnitt in Deutschland aus, Respekt!",
        },
        {
          text: "Erstellen Sie doch mal eine Liste und überprüfen Sie, bei welchen Ausgaben ein nachhaltiger Gedanke möglich wäre.",
        },
        {
          text: "AUCH: Setzen Sie sich gezielt mit Ihren Wünschen auseinander, kaufen sich nur das, was Sie auch wirklich benötigen und achten Sie auf langlebige Produkte.",
        },
        {
          text: "DAHER: Unsere Partner bieten viele nachhaltige Produkte & Dienstleistungen an. Schauen Sie doch mal rein!",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.expenses as ValueModel).value > 250 &&
        (data.consumption.values.expenses as ValueModel).value <= 500,
    },
    {
      title: "monatlichen Ausgaben",
      hints: [
        {
          text: "Ihre Ausgaben sind mit dem Durchschnitt in Deutschland zu vergleichen! ABER: das geht besser!",
        },
        {
          text: "Machen Sie sich doch mal eine Liste und überprüfen, bei welchen Ausgaben sich wirklich der Nachhaltigkeitsgedanke wiederfindet.",
        },
        {
          text: "Und müssen es immer neue Produkte sein? Flohmärkte und Second Hand Läden bieten tolle und qualitativ hochwertige Produkte!",
        },
        {
          text: "AUCH: Setzen Sie sich gezielt mit Ihren Wünschen auseinander, kaufen sich nur das, was Sie auch wirklich benötigen und achten Sie auf langlebige Produkte.",
        },
        {
          text: "DAHER: Unsere Partner bieten viele nachhaltige Produkte & Dienstleistungen an. Schauen Sie doch mal rein!",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.expenses as ValueModel).value > 500 &&
        (data.consumption.values.expenses as ValueModel).value <= 750,
    },
    {
      title: "monatlichen Ausgaben",
      hints: [
        {
          text: "Ihre monatlichen Ausgaben befinden sich deutlich über dem Durchschnitt! Das kann man auf jeden Fall verbessern, zum Wohle Ihres Geldbeutels und der Natur!",
        },
        {
          text: "Überdenken Sie doch mal Ihre Einkäufe und machen Sie sich eine Liste und überprüfen, bei welchen Ausgaben sich wirklich der Nachhaltigkeitsgedanke wiederfindet.",
        },
        {
          text: "Und müssen es immer neue Produkte sein? Flohmärkte und Second Hand Läden bieten tolle Produkte!",
        },
        {
          text: "AUCH: Setzen Sie sich gezielt mit Ihren Wünschen auseinander, kaufen sich nur das, was Sie auch wirklich benötigen und achten Sie auf langlebige Produkte.",
        },
        {
          text: "DAHER: Unsere Partner bieten viele nachhaltige Produkte & Dienstleistungen an. Schauen Sie doch mal rein!",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.expenses as ValueModel).value > 750 &&
        (data.consumption.values.expenses as ValueModel).value <= 1000,
    },
    {
      title: "monatlichen Ausgaben",
      hints: [
        { text: "Für Sie ist Einkaufen wohl ein Hobby ☺" },
        {
          text: "Überdenken Sie doch mal Ihre Einkäufe und machen Sie sich eine Liste und überprüfen, bei welchen Ausgaben sich wirklich der Nachhaltigkeitsgedanke wiederfindet.",
        },
        { text: "Flohmärkte und Second Hand Läden bieten tolle Produkte!" },
        {
          text: "AUCH: Setzen Sie sich gezielt mit Ihren Wünschen auseinander, kaufen sich nur das, was Sie auch wirklich benötigen und achten Sie auf langlebige Produkte.",
        },
        {
          text: "DAHER: Unsere Partner bieten viele nachhaltige Produkte & Dienstleistungen an. Schauen Sie doch mal rein!",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.expenses as ValueModel).value > 1000,
    },
    {
      title: "Konsumverhalten",
      hints: [
        {
          text: "Im Namen der Umwelt: Danke für Ihr umweltbewusstes und nachhaltiges Handeln.",
        },
        {
          text: "Setzen Sie sich weiterhin bewusst mit Ihrem Konsum auseinander.",
        },
        {
          text: "Denken Sie darüber nach, ob das Produkt wirklich gebraucht bzw. getragen wird.",
        },
        {
          text: "DENN: a. ein Drittel der CO2 Emissionen in Deutschland fallen auf die Produktion und den Kauf von Konsumgütern zurück",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.consumptionBehavior as ValueModel).value === 0,
    },
    {
      title: "Konsumverhalten",
      hints: [
        {
          text: "Sehr gut, Sie setzen sich schon bewusst mit dem Thema auseinander.",
        },
        {
          text: "DENN: Ca. ein Drittel der CO2 Emissionen in Deutschland fallen auf die Produktion und den Kauf von Konsumgütern zurück",
        },
        {
          text: "Setzen Sie sich weiterhin bewusst mit Ihrem Konsum auseinander.",
        },
        {
          text: "Denken Sie darüber nach, ob das Produkt wirklich gebraucht bzw. getragen wird.",
        },
        {
          text: "UND: unabhängige Organisationen wie z. B. Stiftung Warentest oder Öko-Test führen vergleichende Produkttests durch und bewerten die Qualität von Produkten nach unterschiedlichen Kriterien.",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.consumptionBehavior as ValueModel).value === 1,
    },
    {
      title: "Konsumverhalten",
      hints: [
        {
          text: "Vor dem Kaufen überlegen: Ist ein Neukauf notwendig? Wenn ja, setzen Sie auf QUALITÄT: hält länger und hat meistens eine bessere CO2 Bilanz",
        },
        {
          text: "DENN: Ca. ein Drittel der CO2 Emissionen in Deutschland fallen auf die Produktion und den Kauf von Konsumgütern zurück",
        },
        { text: "Also: bewusster einkaufen, gezielter hinterfragen" },
        {
          text: "Labels und Produktkennzeichen sind eine wichtige Informationsquelle. Sie informieren über die sozialen oder die ökologischen Bedingungen im Herstellungsprozess. ",
        },
        {
          text: "unabhängige Organisationen führen vergleichende Produkttests durch und bewerten die Qualität von Produkten nach unterschiedlichen Kriterien.",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.consumptionBehavior as ValueModel).value === 2,
    },
    {
      title: "Konsumverhalten",
      hints: [
        {
          text: "Setzen Sie sich bewusst mit Ihrem Einkaufsverhalten auseinander. Sie tun nicht nur der Umwelt was Gutes sondern auch Ihrem Bankkonto, und das nachhaltig",
        },
        {
          text: "DENN: Ca. ein Drittel der CO2 Emissionen in Deutschland fallen auf die Produktion und den Kauf von Konsumgütern zurück",
        },
        {
          text: "Labels und Produktkennzeichen sind eine wichtige Informationsquelle. Sie informieren über die sozialen oder die ökologischen Bedingungen im Herstellungsprozess.",
        },
        {
          text: "unabhängige Organisationen wie z. B. Stiftung Warentest oder Öko-Test führen vergleichende Produkttests durch und bewerten die Qualität von Produkten nach unterschiedlichen Kriterien.",
        },
        {
          text: "Was so gut wie immer geht: Reparieren statt wegwerfen, weniger kaufen, Qualität statt Masse, Weniger ist Mehr, Entsorge Umweltfreundlich",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.consumptionBehavior as ValueModel).value === 3,
    },
    {
      title: "Second Hand",
      hints: [
        {
          text: "Sie haben ein tolles Bewusstsein für einen nachhaltigen Umgang mit unseren kostbaren Ressourcen - Schnäppchenjäger und Flohmarkttrödler sind stolz auf Sie!",
        },
        {
          text: "Veranstalten Sie doch mal ne Kleidertauschparty und zeigen Sie ihren Freundes- und Bekanntenkreis, dass Second Hand auch Spaß machen kann.",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.secondHand as ValueModel).value === 0,
    },
    {
      title: "Second Hand",
      hints: [
        {
          text: "Dass Sie gelegentlich zu Second Hand Produkten greifen, ist schon richtig gut.",
        },
        {
          text: "Vielleicht prüfen Sie künftig noch einmal mehr, ob es nicht lohnt ein gebrauchtes Produkt zu kaufen. Hier lassen sich oft Schätze finden.",
        },
        {
          text: "Und nehmen Sie sich doch vor, immer zunächst nach gebrauchten Gegenständen zu suchen, anstatt gleich die Neupreise zu checken",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.secondHand as ValueModel).value === 1,
    },
    {
      title: "Second Hand",
      hints: [
        {
          text: "Das nicht jeder es cool findet getragene Kleidung zu kaufen, verstehen wir. Aber vielleicht mal beim nächsten Möbelstück oder beim nächsten Rad darüber nachdenken, ob es nicht auch zweite Hand sein kann?",
        },
        {
          text: "Hochwertige (KINDER-)KLEIDUNG aus zweiter Hand finden Sie nicht nur auf Flohmärkten, in SecondHand-Läden und auf Online-Portalen",
        },
        {
          text: "Nutzen Sie die Angebote spezialisierter (Online-) Anbieter: Gebrauchte PCS, LAPTOPS SOWIE ENDGERÄTE wie Smartphone und Tablet werden wiederaufbereitet („refurbishing“) und zu fairen Preisen verkauft.",
        },
        {
          text: "Gut erhaltenes SPIELZEUG, BÜCHER, CDS/DVDS & CO. finden Sie neben speziellen Gebrauchtwarenläden ebenfalls auf einschlägigen Online-Plattformen oder in den Second Hand Läden um die Ecke.",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.secondHand as ValueModel).value === 2,
    },
    {
      title: "Second Hand",
      hints: [
        {
          text: "Immer alles neu kaufen verbraucht so viel Ressourcen. Informieren Sie sich über Flohmärkte, Second Hand Geschäfte oder lokale Nachbarschafts-Tauschbörsen in Ihrer Umgebung.",
        },
        {
          text: "Hochwertige (KINDER-)KLEIDUNG aus zweiter Hand finden Sie nicht nur auf Flohmärkten, in SecondHand-Läden und auf Online-Portalen",
        },
        {
          text: "Nutzen Sie die Angebote spezialisierter (Online-) Anbieter: Gebrauchte PCS, LAPTOPS SOWIE ENDGERÄTE wie Smartphone und Tablet werden wiederaufbereitet („refurbishing“) und zu fairen Preisen verkauft.",
        },
        {
          text: "Gut erhaltenes SPIELZEUG, BÜCHER, CDS/DVDS & CO. finden Sie neben speziellen Gebrauchtwarenläden ebenfalls auf einschlägigen Online-Plattformen oder in den Second Hand Läden um die Ecke. ",
        },
      ],
      condition: (data: DataModel) =>
        (data.consumption.values.secondHand as ValueModel).value === 3,
    },
  ],
  hobbies: [
    {
      title: "Haustiere",
      hints: [
        {
          text: "WICHTIG VORAB: das Wohl des Tieres steht im Vordergrund und sollte auch bei allen Entscheidungen und Handlungen das Wichtigste sein.",
        },
        {
          text: "Doch ist es wichtig zu wissen, dass sich die Ökobilanz Ihres Haustieres direkt auch auf Ihre auswirkt.",
        },
        {
          text: "DOCH: Sie können etwas tun, um die Ökobilanz Ihres Haustieres zu verbessern.",
        },
        {
          text: "Studien zeigen u.a., dass die Art der Haltung sowie das Futter einen markanten Einfluss auf die Umweltbelastung haben kann.",
        },
      ],
      condition: (data: DataModel) => true,
    },
    {
      title: "Fische",
      hints: [
        {
          text: "Die Ökobilanz eines Fisches entspricht einer jährlichen Autofahrleistung von ca. 14 Kilometern",
        },
        {
          text: "Aquarien haben einen hohen Energieverbrauch. Besonders einfach lässt sich Energie sparen, wenn die Fische mit einer niedrigeren Wassertemperatur auskommen. Ein 200-Liter-Aquarium mit 25 statt 28 Grad Celsius spart 100 kWh Strom (52 kg CO2) und 30 Euro Stromkosten pro Jahr.",
        },
        {
          text: "Achten Sie bei den elektrischen Geräten auf LED Röhren und Vorschaltgeräte",
        },
        {
          text: "Ist eine Sauerstoffpumpe notwendig? Der Wasserfilter bewegt die Wasseroberfläche bereits so stark, dass in der Regel genügend Sauerstoff ins Wasser gelangt.",
        },
        { text: "UND: Dämmen hilft, dass die Wärme im Aquarium bleibt!" },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.pets as ValueGroup).values.fishes.isEnabled,
    },
    {
      title: "Nagetier",
      hints: [
        {
          text: "Die Ökobilanz eines Nagetiers entspricht einer jährlichen Autofahrleistung von ca. 700 Kilometern",
        },
        { text: "Futter selbst herstellen? Geht!" },
        {
          text: "Nagetiere kommen ohne industriell hergestelltes Tierfutter aus. Mit Obst und Gemüse aus Bio-Anbau, frischem Wiesenheu und Wasser bekommen die kleinen Nager viel Abwechslung und glänzen zugleich mit mehr Klimaschutz. ",
        },
        {
          text: "Viele Haustiere sind zu dick. Achten Sie darauf, dass Sie Ihr Haustier nicht überfüttern, denn auch das ist schlecht fürs Klima und für das Tier natürlich auch.",
        },
        {
          text: "Achten Sie bei Verpackungen auf möglichst wenig Plastik oder nutzen Sie wiederverwendbare Verpackungen.",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.pets as ValueGroup).values.rodents.isEnabled,
    },
    {
      title: "Katzen",
      hints: [
        {
          text: "Die Ökobilanz eines Katze entspricht einer jährlichen Autofahrleistung von ca. 1.400 Kilometern",
        },
        {
          text: "Allein das Futter ist für einen großen Teil verantwortlich: Schon mal daran gedacht, das Futter selbst herzustellen? Der große Vorteil: Sie wissen ganz genau, was drin ist, woher es stammt und wie sich die Zutaten auf unser Klima auswirken.",
        },
        {
          text: "Auch Katzen kommen mit weniger Fleisch zurecht, probieren Sie es aus!",
        },
        {
          text: "Sie finden auch BIO-Katzennahrung. Doppelt gut: schützt die Umwelt und ist gut für Ihre Katze",
        },
        {
          text: "Verwenden Sie kompostierbares Katzenstreu – und kompostieren Sie es möglichst selbst.",
        },
        {
          text: "Achten Sie bei Verpackungen auf möglichst wenig Plastik oder nutzen Sie wiederverwendbare Verpackungen.",
        },
        {
          text: "Viele Haustiere sind zu dick. Achten Sie darauf, dass Sie Ihr Haustier nicht überfüttern, denn auch das ist schlecht fürs Klima und für das Tier natürlich auch.",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.pets as ValueGroup).values.cats.isEnabled,
    },
    {
      title: "Hunde",
      hints: [
        {
          text: "Die Ökobilanz eines Hundes entspricht einer jährlichen Autofahrleistung von ca. 3.700 Kilometern",
        },
        {
          text: "Allein das Futter ist für einen großen Teil verantwortlich: Schon mal daran gedacht, das Futter selbst herzustellen? Der große Vorteil: Sie wissen ganz genau, was drin ist, woher es stammt und wie sich die Zutaten auf unser Klima auswirken.",
        },
        {
          text: "Etwas besser als konventionell erzeugtes Fleisch wäre immerhin Fleisch aus Bio-Produktion. Hier sind die Umweltschäden etwas geringer.",
        },
        {
          text: "Achten Sie bei Verpackungen auf möglichst wenig Plastik oder nutzen Sie wiederverwendbare Verpackungen.",
        },
        {
          text: "Viele Haustiere sind zu dick. Achten Sie darauf, dass Sie Ihr Haustier nicht überfüttern, denn auch das ist schlecht fürs Klima und für das Tier natürlich auch.",
        },
        {
          text: "Führe beim Gassigehen stets biologisch abbaubare Kotsäckchen für das Geschäft deines Hundes mit. So hältst du die Straßen sauber und meidest Plastik.",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.pets as ValueGroup).values.dogs.isEnabled,
    },
    {
      title: "Pferd",
      hints: [
        {
          text: "Die Ökobilanz eines Pferdes entspricht einer jährlichen Autofahrleistung von ca. 21.500 Kilometern.",
        },
        {
          text: "Achten Sie bei Verpackungen für Futter auf möglichst wenig Plastik oder nutzen Sie wiederverwendbare Verpackungen.",
        },
        {
          text: "UND: Verwenden Sie Bio Futter, das ist gut für die Umwelt und das Tier!",
        },
        {
          text: "Verwenden Sie anstelle von Stroh als Streu lokale Hobelspäne, dann sinkt die Umweltbelastung immerhin um fast 30 Prozent.",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.pets as ValueGroup).values.dogs.isEnabled,
    },
    {
      title: "Camping-Urlaub",
      hints: [
        {
          text: "Sie bekommen ein großes Plus: Camping zählt zu den umweltfreundlichsten Arten, Urlaub zu machen.",
        },
        {
          text: "Dennoch: versuchen Sie lange Anreisestrecken zu vermeiden und prüfen Sie doch, ob Sie nicht das Auto daheimlassen können.",
        },
        {
          text: "Grundsätzlich ist es sinnvoll, lieber einen längeren Urlaub zu verbringen als mehrere kurze; das spart Reisewege und erhöht zudem den Erholungswert.",
        },
        {
          text: "Sie können sich bei den meisten Campinganbietern nach der Umweltfreundlichkeit erkundigen: wird z.B. Solarenergie genutzt oder sind eigene Gemüse- und Obstgärten vorhanden?",
        },
        {
          text: "Achten Sie auf bestimmte Zertifikate der Camping-Plätze: viele legen hier einen Wert auf Nachhaltigkeit!",
        },
        { text: "UND: Vermeiden Sie Müll und kaufen Sie bewusst ein!" },
        {
          text: "Bei unseren Partnern finden Sie dazu tolle Angebote für Ihre nächste, nachhaltige Urlaubsreise!",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.holiday as ValueGroup).values.camping.isEnabled,
    },
    {
      title: "Ferienwohnungen",
      hints: [
        {
          text: "Urlaub in Airbnb- bzw. Ferienwohnungen ist schon mal super! Hier können Sie im Vergleich zu anderen Urlaubsvarianten CO2 einsparen.",
        },
        {
          text: "Achten Sie aber auch hier, dass Ihr Ziel nicht zu weit entfernt ist und nutzen Sie für die Anreise nachhaltige Verkehrsmittel wie Bus oder Bahn.",
        },
        {
          text: "Grundsätzlich ist es sinnvoll, lieber einen längeren Urlaub zu verbringen als mehrere kurze; das spart Reisewege und erhöht zudem den Erholungswert.",
        },
        {
          text: "Erkundigen Sie sich, ob nachhaltige Siegel bei dem Urlaubsort bzw. der Unterkunft vorliegen",
        },
        {
          text: "Entdecke während der Urlaubsreise mehr als das, was im Reiseführer steht. Essen Sie in einheimischen Restaurants, kaufe Souvenirs in kleinen Geschäften und probiere regionale, landestypische Besonderheiten",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.holiday as ValueGroup).values.airbnb.isEnabled,
    },
    {
      title: "Urlaub im Hotel",
      hints: [
        {
          text: "Urlaub in einem Hotel mit Halbpension oder All-Inclusive ist an sich nichts Schlechtes. Trotzdem sollten Sie bei Ihrer nächsten Buchung ein paar Dinge beachten, damit dieser Urlaub nachhaltig wird",
        },
        { text: "Ist das Hotel evtl. zertifiziert oder ein Bio-Hotel?" },
        {
          text: "Ökologisch ausgerichtete Hotels legen Wert auf eine umweltschonende Ressourcennutzung, wie etwa durch Solarenergie, Abfalldezimierung und Aufbereitung von Wasser.",
        },
        {
          text: "Fair bezahlte Arbeitsplätze und gute Arbeitsbedingungen sind dabei nicht zu vergessen und wichtig",
        },
        {
          text: "Versuchen Sie Anreisestrecke so kurz wie möglich zu halten und greifen Sie auf öffentliche Verkehrsmittel zurück.",
        },
        {
          text: "Grundsätzlich ist es sinnvoll, lieber einen längeren Urlaub zu verbringen als mehrere kurze; das spart Reisewege und erhöht zudem den Erholungswert.",
        },
        {
          text: "Erkundigen Sie die Region vor Ort doch mal per Rad oder zu Fuß. Manche schöne Ecken oder Geheimplätze lassen sich nur so finden.",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.holiday as ValueGroup).values.hotel.isEnabled ||
        !!(data.hobbies.values.holiday as ValueGroup).values.allInclusive
          .isEnabled,
    },
    {
      title: "Kreuzfahrt-Reisen",
      hints: [
        {
          text: "Auf einer siebentägigen Kreuzfahrt auf einem Schiff mit 2000 bis 3000 Passagieren zu zweit in einer Standardkabine ist der einzelne Gast für 1,5 Tonnen CO2 verantwortlich. Fasst das Schiff nur 500 bis 1000 Gäste, sind es schon 2,4 Tonnen.",
        },
        {
          text: "Die Luftverschmutzung, die Benutzung von Schweröl und der große Wasser- und Energieverbrauch sind nur ein paar Faktoren, die zu dem gewaltigen CO2 Ausstoß beitragen",
        },
        {
          text: "Grundsätzlich ist es sinnvoll, lieber einen längeren Urlaub zu verbringen als mehrere kurze; das spart Reisewege und erhöht zudem den Erholungswert.",
        },
        {
          text: "ABER: der Naturschutzbund Deutschland erfasst alle Kreuzfahrten jährlich in einer Liste und beurteilt diese. Informieren Sie sich hier, welche Kreuzfahrt ökologisch noch am besten ist",
        },
        {
          text: "Also: Entdecke Alternativen, erkundigen Sie Ihre Region und Ihr Land und lassen Sie sich einmal bewusst auf einen nachhaltigen Urlaub ein",
        },
      ],
      condition: (data: DataModel) =>
        !!(data.hobbies.values.holiday as ValueGroup).values.airbnb.isEnabled,
    },
    {
      title: "Streaming",
      hints: [
        {
          text: "Wow, so selten streamen Sie Filme und Serien von Mediatheken? Darauf können Sie stolz sein und liegen damit deutlich unter den Durchschnitt (6,5 Stunden) in Deutschland!",
        },
        {
          text: "ABER: Wenn Ihnen eine Serie oder Film nicht zusagt, einfach ausschalten!",
        },
        {
          text: "UND Achtsam bleiben: Wenn Sie einen Film anschauen, sollten Sie nicht gleichzeitig am Handy hängen oder surfen –Sie bekommen die Handlung nicht richtig mit.",
        },
        {
          text: "WISSENSWERT: Aktuelle Studien zeigen, dass Video-Streaming allein 2018 mehr als 300 Millionen Tonnen CO2-Äquivalente verursachte. Das entspricht der Menge, die das gesamte Land Spanien in einem Jahr ausstößt.",
        },
        {
          text: "ZUM SCHLUSS: Es muss nicht immer 8K-Qualität sein – die meisten Videos können Sie auch in geringerer Qualität super gut wiedergeben.",
        },
      ],
      condition: (data: DataModel) =>
        (data.hobbies.values.streaming as ValueModel).value <= 4,
    },
    {
      title: "Streaming",
      hints: [
        {
          text: "Genau der Durchschnittswert in Deutschland! Allein auf den bekannten Mediatheken streamt der Deutsche ca. 6,5 Stunden pro Woche.",
        },
        {
          text: "NUN: Überlegen Sie sich doch genau, ob sich der Film oder die Serie wirklich lohnen.",
        },
        {
          text: "HEIßT: Wenn Ihnen eine Serie oder Film nicht zusagt, einfach ausschalten!",
        },
        {
          text: "UND Achtsam bleiben: Wenn Sie einen Film anschauen, sollten Sie nicht gleichzeitig am Handy hängen oder surfen –Sie bekommen die Handlung nicht richtig mit.",
        },
        {
          text: "ALSO: mal Digital Detox? Nicht jeden Abend vor dem Bildschirm verbringen, sondern mal ein Buch lesen, die Natur genießen oder ein Brettspiel gemeinsam mit der Familie oder Freunden spielen, das tut gut.",
        },
        { text: "FAZIT: Bewusst offline gehen!!" },
        {
          text: "WISSENSWERT: Aktuelle Studien zeigen, dass Video-Streaming allein 2018 mehr als 300 Millionen Tonnen CO2-Äquivalente verursachte. Das entspricht der Menge, die das gesamte Land Spanien in einem Jahr ausstößt.",
        },
        {
          text: "ZUM SCHLUSS: Es muss nicht immer 8K-Qualität sein – die meisten Videos können Sie auch in geringerer Qualität super gut wiedergeben.",
        },
      ],
      condition: (data: DataModel) =>
        (data.hobbies.values.streaming as ValueModel).value < 9 &&
        (data.hobbies.values.streaming as ValueModel).value > 4,
    },
    {
      title: "Streaming",
      hints: [
        {
          text: "Hui, Streamen ist wohl ein richtiges Hobby von Ihnen. Mit Ihrem Wert liegen Sie über dem Durchschnitt (6,5 Stunden) in Deutschland.",
        },
        {
          text: "NUN: Überlegen Sie sich doch genau, ob sich der Film oder die Serie wirklich lohnen.",
        },
        {
          text: "HEIßT: Wenn Ihnen eine Serie oder Film nicht zusagt, einfach ausschalten!",
        },
        {
          text: "UND Achtsam bleiben: Wenn Sie einen Film anschauen, sollten Sie nicht gleichzeitig am Handy hängen oder surfen –Sie bekommen die Handlung nicht richtig mit.",
        },
        {
          text: "ALSO: mal Digital Detox? Nicht jeden Abend vor dem Bildschirm verbringen, sondern mal ein Buch lesen, die Natur genießen oder ein Brettspiel gemeinsam mit der Familie oder Freunden spielen, das tut gut.",
        },
        { text: "FAZIT: Bewusst offline gehen!" },
        {
          text: "WISSENSWERT: Aktuelle Studien zeigen, dass Video-Streaming allein 2018 mehr als 300 Millionen Tonnen CO2-Äquivalente verursachte. Das entspreche der Menge, die das gesamte Land Spanien in einem Jahr ausstößt.",
        },
        {
          text: "ZUM SCHLUSS: Es muss nicht immer 8K-Qualität sein – die meisten Videos können Sie auch in geringerer Qualität super gut wiedergeben.",
        },
      ],
      condition: (data: DataModel) =>
        (data.hobbies.values.streaming as ValueModel).value > 8,
    },
  ],
  public: [],
};
