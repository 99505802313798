import React, { useState } from "react";

interface Props {
  hint: string;
}

export const Hint = ({ hint }: Props) => {
  const [showHint, setShowHint] = useState(false);
  return (
    <div
      className="w-6 h-6 ml-4 focus:outline-none relative w-xl"
      onMouseEnter={() => {
        setShowHint(true);
      }}
      onMouseLeave={() => {
        setShowHint(false);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      {showHint && (
        <span className="absolute right-4 top-4 bg-white shadow-md rounded-xl z-10 p-4 w-96" dangerouslySetInnerHTML={{__html: hint}}>
        </span>
      )}
    </div>
  );
};
