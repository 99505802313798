import React, { ReactNode } from "react";
var classNames = require("classnames");

interface Props {
  children: ReactNode;
  disabled?: boolean;
  className?: string
}

export const H2: React.FC<Props> = ({ className, children, disabled }) => (
  <h2
    className={classNames(className, "font-semibold text-lg", {
      "text-gray-400": disabled,
      "text-gray-800": !disabled,
    })}
  >
    {children}
  </h2>
);
