import { observer } from "mobx-react-lite";
import React from "react";
import { MultipleChoiceInput, SliderInput } from "..";
import { ValueGroup, ValueModel } from "../../types";
import { isSliderInput } from "../../util";
import { Hint } from "../Hint";
import { H2 } from "../typo";

interface Props {
  value: ValueGroup;
  groupKey: string;
  onValueChanged: (key: string, subkey: string, value: number) => void
  onToggleChanged: (key: string, subkey: string, value: boolean) => void
  onChipChanged: (key: string, subkey: string, value: boolean) => void
}

const Chip = (value: ValueModel, onChecked: (checked: boolean) => void) => (
  <label className="inline-flex items-center mt-3 mr-3">
    <input
      type="checkbox"
      className="form-checkbox rounded h-5 w-5 text-green-600 focus:ring-0"
      checked={value.isEnabled}
      onChange={(event) => {
        onChecked(event.target.checked);
      }}
    />
    <span className="ml-2 text-gray-700">{value.title}</span>
  </label>
);

export const ValueGroupInput = observer(({
  value,
  groupKey,
  onValueChanged,
  onToggleChanged,
  onChipChanged
}: Props) => {
  return (
    <div>
      <div className="flex flex-row justify-between mt-6">
        <H2>{value.title}</H2>
        {value.hint && <Hint hint={value.hint}></Hint>}
      </div>
      <p className="mb-4 text-sm grid-4">{value.subtitle}</p>
      {Object.keys(value.values).map((key) => {
        const currentValue = value.values![key];
        return Chip(currentValue, (checked) => {
          onChipChanged(groupKey, key, checked)
        });
      })}
      <div className="pt-4"></div>
      {Object.keys(value.values)
        .filter((key) => value.values![key].isEnabled)
        .map((key) => {
          const currentValue = value.values![key];

          return isSliderInput((currentValue as ValueModel).input) ? (
            <SliderInput
              value={currentValue as ValueModel}
              onValueChanged={(value) => onValueChanged(groupKey, key, value)}
              onToggleChanged={(value) => onToggleChanged(groupKey, key, value)}
              isCondensed
            ></SliderInput>
          ) : (
            <MultipleChoiceInput
              value={currentValue as ValueModel}
              onValueChanged={(value) => onValueChanged(groupKey, key, value)}
            ></MultipleChoiceInput>
          );
        })}
      <div className="pt-4"></div>
    </div>
  );
});
