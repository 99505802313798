import React from "react"; // importing FunctionComponent
import { AnswerModel } from "../../types";
var classNames = require("classnames");

type Props = {
  isSelected?: boolean;
  answer: AnswerModel;
  onClick: () => void;
};

export const AnswerRow: React.FC<Props> = ({
  isSelected = false,
  answer,
  onClick,
}) => {
  return (
    <a href="#" onClick={() => onClick()} className="flex space-x-4 pb-1">
      <div
        className={classNames(
          "flex-shrink-0 my-2 w-3 h-3 rounded-full ring-2 ring-gray-600 ring-offset-2 ml-2",
          {
            "bg-green-600": isSelected,
          }
        )}
      ></div>
      <div className="mt-0.5">
        <span className="font-semibold text-base mt-2">{answer.text}</span>
        {answer.subtext && <div>{answer.subtext}</div>}
      </div>
    </a>
  );
};
