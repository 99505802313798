import {
  DataModel,
  MultipleChoiceInputModel,
  SectionModel,
  SliderInputModel,
  ValueGroup,
  ValueModel,
} from "../types";

export const isSliderInput = (
  input: SliderInputModel | MultipleChoiceInputModel
): input is SliderInputModel => (<SliderInputModel>input).min !== undefined;

const colors = [
  "bg-shades-sx",
  "bg-shades-sm",
  "bg-shades-md",
  "bg-shades-lg",
  "bg-shades-xl",
];
const assets = Array.from(
  new Array(13),
  (item, index) => `Tree ${index + 1}.svg`
);

export const getLevelColor = (value: number) =>
  colors[Math.floor(Math.min(value, 1) * (colors.length - 1))];
export const getLeveledAsset = (value: number) =>
  assets[Math.floor(Math.max(0, Math.min(value, 1)) * (assets.length - 1))];

export const isSingleInput = (
  input: ValueModel | ValueGroup
): input is ValueModel => (<ValueModel>input).input !== undefined;

export const data2json = (data: DataModel) => {
  const out: Record<string, unknown> = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const { ref, ...section } = data[key];
      out[key] = section;
    }
  }
  return out;
};

function _copyValue(from: ValueModel, to: ValueModel) {
  if (typeof from?.value === "undefined") return;
  to.value = from.value;
  to.isEnabled = from.isEnabled;
  to.isElectric = from.isElectric;
}

export const json2data = (data: DataModel, json: Record<string, unknown>) => {
  for (const section in data) {
    if (Object.prototype.hasOwnProperty.call(data, section)) {
      const jsonSection = json[section] as SectionModel | undefined;
      if (!jsonSection) continue;

      if (jsonSection.value) {
        data[section].value = jsonSection.value;
      }

      for (const v of Object.keys(data[section].values)) {
        if (!(v in jsonSection.values)) continue;

        const target = data[section].values[v];
        if (isSingleInput(target)) {
          _copyValue(jsonSection.values[v] as ValueModel, target);
        } else {
          for (const val of Object.keys(target.values)) {
            _copyValue(
              (jsonSection.values[v] as ValueGroup).values[val],
              target.values[val]
            );
          }
        }
      }
    }
  }
};

/**
 * @deprecated The embed.js-flow deprecated and so is the
 * `window.CO2_CALCULATOR_ORIGIN` global variable. Please
 * use the `<iframe>`-flow and `window.location.origin`
 * instead. To read the origin of the embedding site, use
 * `document.referrer` instead.
 */
export function getOrigin(): string {
  const embed = (window as any).CO2_CALCULATOR_ORIGIN;
  if (typeof embed === "string") return embed;
  return window.location.origin
}
