import { flow, makeAutoObservable } from "mobx";
import { DataStore } from ".";
import EmbedApi from "../embed-api";
import { data2json } from "../util";

const FIREBASE_FUNCTION_BASEURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/twigbit-co2-calculator/europe-west1"
    : "https://europe-west1-twigbit-co2-calculator.cloudfunctions.net";

export class ConversionDialogStore {
  private readonly dataStore: DataStore;

  state: "idle" | "loading" | "error" | "success" = "idle";

  constructor(dataStore: DataStore) {
    this.dataStore = dataStore;
    makeAutoObservable(
      this,
      {
        submit: flow,
      },
      { autoBind: true }
    );
  }

  get isLoading() {
    return this.state === "loading";
  }
  get isDone() {
    return this.state === "success";
  }
  get isError() {
    return this.state === "error";
  }

  *submit(email: string, subscribeNewsletter: boolean = false) {
    if (this.state === "loading") return;
    this.state = "loading";

    try {
      yield this.requestPdfReport(email, subscribeNewsletter);
      this.state = "success";
    } catch (err) {
      // TODO: distinguish internal and network errors
      this.state = "error";
    }
  }

  private async requestPdfReport(
    email: string,
    subscribeNewsletter: boolean = false
  ): Promise<void> {
    const response = await fetch(
      `${FIREBASE_FUNCTION_BASEURL}/requestPdfReport`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            email,
            subscribeNewsletter: subscribeNewsletter,
            values: data2json(this.dataStore.data),
          },
          context: EmbedApi.embeddingContext,
        }),
      }
    );

    if (response.status !== 200) {
      throw new Error("failed calling function `requestPdfReport`");
    }

    const data = await response.json();
    if (!data || !data.success) {
      throw new Error("failed calling function `requestPdfReport`");
    }
  }
}
