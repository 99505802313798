import React from 'react'
var classNames = require("classnames");

interface Props {
    isEnabled: boolean,
    onChanged: (value: boolean) => void,
}

export const Toggle = ({ isEnabled, onChanged }: Props) => {

    return (
        < button onClick={() => { onChanged(!isEnabled) }} type="button" className={classNames("bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600", { "bg-green-600": isEnabled })} >
            {/* <span className="sr-only">Use setting</span> */}
            {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
            <span className={classNames("translate-x-0 left-0 top-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200", { "translate-x-5": isEnabled }, { "translate-x-0": !isEnabled })}>
                <span className={classNames("absolute inset-0 h-full w-full flex items-center justify-center transition-opacity", { "opacity-100 ease-in duration-200": !isEnabled }, { "opacity-0 ease-out duration-100": isEnabled })} aria-hidden="true">
                    <svg className="bg-white h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                {/* Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" */}
                <span className={classNames("opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity", { "opacity-100 ease-in duration-200": isEnabled })} aria-hidden="true">
                    <svg className="bg-white h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                </span>
            </span>
        </button >
    )
}
