import React, { ReactNode } from "react"; // importing FunctionComponent
import { getLevelColor } from "../util";
import { H1 } from "./typo";
var classNames = require("classnames");


type Props = {
  progress: number;
  value?: number;
  isActive: boolean;
  children?: ReactNode;
  showResultPin?: boolean;
  pin?: { progress: number, title: string, value: number }
};

export const ProgressBar: React.FC<Props> = ({
  progress,
  children,
  pin,
  showResultPin,
  value,
}) => (
  <div className="relative mb-24 mt-24 font-display">
    <div className="flex h-4 rounded-md bg-gray-200">
      <div
        className={classNames(`rounded-l-md ${getLevelColor(progress / 100)}`, {
          "rounded-md": !showResultPin,
        })}
        style={{ width: `${progress}%` }}
      >
        {children}
      </div>
    </div>
    {showResultPin && (
      <div
        className="absolute bottom-0 origin-bottom-right"
        style={{ left: `${Math.min(progress, 100)}%` }}
      >
        <div>
          <div
            className={classNames("flex flex-col text-md", {
              "transform -translate-x-full items-end": progress > 80,
            })}
          >
            <span className="text-sm font-medium">Mein Ergebnis</span>
            <div className="flex flex-row">
              <H1>{`${(value ?? 0.0).toFixed(2)} t`}</H1>
            </div>
            <span className="text-gray-500 text-xs">CO2 Äquivalente</span>
          </div>
        </div>
        <div className="relative w-1 bg-gray-600 h-8 rounded-md" />
      </div>
    )}
    {pin && (
      <div className="absolute top-0" style={{ left: `${pin.progress}%` }}>
        <div className="w-1 bg-gray-600 h-8 rounded-md" />
        <div className="text-sm font-medium">{pin.title}</div>
        <div className="flex flex-row items-center">
          <H1>{`${(pin.value ?? 0.0).toFixed(2)} t`}</H1>
        </div>
        <span className="text-gray-500 text-xs">CO2 Äquivalente</span>
      </div>
    )}
  </div>
);
