import { observer } from "mobx-react-lite";
import React from "react"; // importing FunctionComponent
import { AnswerRow } from "..";
import { AnswerModel, MultipleChoiceInputModel, ValueModel } from "../../types";
import { Hint } from "../Hint";
import { H2 } from "../typo";
var classNames = require("classnames");

type Props = {
  value: ValueModel;
  onValueChanged: (value: number) => void;
};

export const MultipleChoiceInput: React.FC<Props> = observer(({
  value,
  onValueChanged,
}) => {
  return (
    <div className="py-8">
      <div className="flex flex-row justify-between">
        <H2>{value.title}</H2>
        {value.hint && <Hint hint={value.hint}></Hint>}
      </div>
      <p className="mb-4 text-sm">{value.subtitle}</p>
      <div
        className={classNames("grid grid-cols-1 md:grid-cols-2 gap-4 h-full", {
          "lg:grid-cols-4": (value.input as MultipleChoiceInputModel)
            .isCondensed,
        })}
      >
        {(value.input as MultipleChoiceInputModel).answers.map(
          (item: AnswerModel, index: number) => (
            <AnswerRow
              isSelected={index === value.value}
              answer={item}
              onClick={() => {
                onValueChanged(index);
                if (onValueChanged) onValueChanged(index);
              }}
            ></AnswerRow>
          )
        )}
      </div>
    </div>
  );
});
