import { observer } from "mobx-react-lite";
import { ProgressBar } from ".";
import useStores from "../hooks";
import { H1 } from "./typo";

interface Props {}

export const ResultsSection = observer((props: Props) => {
  const averageGermany = 10.83;
  const { emission } = useStores();
  const progressPercentage = (100 * (emission - 5)) / (20 - 5);
  const progressAverage = (100 * (averageGermany - 5)) / (20 - 5);
  return (
    <>
      <div className="flex flex-row flex-wrap space-x-4 p-4 mt-8 items-center bg-green-600 rounded-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
          />
        </svg>
        <div className="flex-1 white">
          <h1 className="text-lg text-white font-semibold">Mein Ergebnis</h1>
        </div>
      </div>
      <div className="mt-8">
        <H1>Vergleich</H1>
        <span className="pt-2">
          So haben Sie im Vergleich mit dem Rest der Nation abgeschlossen.
        </span>
        <ProgressBar
          showResultPin
          value={emission}
          pin={{
            progress: progressAverage,
            title: "Durchschnitt Deutschland",
            value: averageGermany,
          }}
          progress={progressPercentage}
          isActive
        ></ProgressBar>
      </div>
      <div></div>
    </>
  );
});
