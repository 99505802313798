import { ResultOverview } from "../ResultOverview";

const dateFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
} as const;

export const FrontPage = () => (
  <div className="print:page-break-after-always">
    <div className="h-40" />
    <h1 className="text-4xl font-semibold text-gray-800">
      Ihr persönlicher
      <br />
      Nachhaltigkeitsbericht
    </h1>
    <p className="mt-4 mb-8 text-sm text-gray-600">
      Erstellt am {new Date().toLocaleDateString("de-DE", dateFormatOptions)}
    </p>
    <p className="mt-4 mb-12 max-w-xl">
      Hier sehen Sie Ihren persönlichen Nachhaltigkeitsbericht mit allen
      Ergebnissen und individuellen Handlungsempfehlungen aufgrund Ihrer
      Antworten. Vergleichen Sie Ihre Werte mit den Durchschnittszahlen in
      Deutschland und viel Spaß beim Kennenlernen Ihrer individuellen Tipps &
      Tricks.
    </p>
    {/* <p>Ihre Sparkasse Bodensee</p> */}
    <div className="mt-12 max-w-xs">
      <ResultOverview />
    </div>
  </div>
);
