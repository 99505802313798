import { observer } from "mobx-react-lite";
import React from "react";
import { ProgressBar } from ".";
import useStores from "../hooks";
import { getLeveledAsset } from "../util";
import { asset } from "../util/assets";
import { H1 } from "./typo";
var classNames = require("classnames");

interface Props {}

export const ResultOverview = observer((props: Props) => {
  const { emission } = useStores();
  const progressPercentage = (100 * (emission - 10)) / (20 - 5);

  return (
    <div className="flex flex-row items-center">
      <a href="#" className="flex-grow">
        <div
          className={classNames(
            "flex-col space-y-2 md:block md:p-4 p-1 rounded-lg",
            {}
          )}
        >
          <div className="flex flex-row flex-wrap space-x-4 items-center">
            <div className={classNames("flex-1")}>
              <H1 disabled={false}>Mein Ergebnis</H1>
            </div>
          </div>

          <ProgressBar
            progress={progressPercentage}
            isActive={true}
          ></ProgressBar>

          <div className="flex flex-row items-center ">
            <H1 disabled={false}>{`${emission.toFixed(2)}`}</H1>
            <span className="text-gray-500 text-xs ml-2">
              Tonnen CO2 Äquivalente
            </span>
          </div>
        </div>
      </a>
      <img
        src={asset(`illus/${getLeveledAsset(progressPercentage / 100)}`)}
        alt="The Section Icon"
        className="h-16 w-12"
      ></img>
    </div>
  );
});
