import React from "react";
import { asset } from "../util/assets";

export const Reference = () => {
  return (
    <div className="flex flex-row items-center justify-end p-4 space-x-4">
      <h2 className="text-gray-600 text-xs">powered by</h2>
      <a href="https://www.twigbit.com" target="_blank" rel="noreferrer">
        <img
          src={asset(`logo-twigbit.svg`)}
          alt="twigbit Icon"
          className="max-h-5 pr-4"
        ></img>
      </a>
      <a
        href="https://twigbit.notion.site/6ef4508b5add4677b69d9dc56e925dd7?v=41e2ee0205ba418cb9ccfcc35cbefb50"
        target="_blank"
        rel="noreferrer"
      >
        Quellen
      </a>
    </div>
  );
};
