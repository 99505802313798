import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./sparkasse-font.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataStore } from './store';

export const StoreContext = createContext<DataStore | null>(null);
const store = new DataStore();

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StoreContext.Provider>,
  document.getElementById('co2-calculator-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
