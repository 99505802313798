import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { SliderInput } from ".";
import useStores from "../hooks";
import { ValueModel } from "../types";
import { isSingleInput, isSliderInput } from "../util";
import { asset } from "../util/assets";
import ConversionDialog from "./ConversionDialog";
import { MultipleChoiceInput } from "./inputs/MultipleChoiceInput";
import { ValueGroupInput } from "./inputs/ValueGroupInput";
import { FrontPage } from "./print/FrontPage";
import ResultsBody from "./ResultsBody";
import { ResultsSection } from "./ResultsSection";

export const SectionBody = observer(() => {
  const store = useStores();
  const containerRef = useRef<HTMLDivElement>(null);

  let throttleTimeout: number | null = null;
  const updateScroll = () => {
    if (containerRef.current) {
      store.updateScrollPosition(containerRef.current.scrollTop);
    }

    throttleTimeout = null;
  };

  const onScroll = () => {
    if (throttleTimeout === null) {
      throttleTimeout = window.setTimeout(updateScroll, 100);
    }
  };

  // scroll to top on change between result mode and input mode
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
      store.updateScrollPosition(0);
    }
  }, [store, store.isInResultMode, containerRef]);

  return (
    <div
      ref={containerRef}
      onScroll={onScroll}
      className="flex flex-col flex-grow overflow-y-auto overflow-x-hidden bg-gray-50 print:bg-white px-2 pt-4 md:px-8 md:rounded-r-md pb-24"
    >
      {store.activeSectionIndex === 4 && !store.isInResultMode && (
        <button
          onClick={() => {
            store.setIsInResultMode(true);
            store.goToSection(0);
          }}
          className="absolute flex flex-row bottom-16 md:bottom-4 right-0 items-center bg-green-600 hover:shadow-md hover:bg-green-700 text-white font-semibold py-4 px-4 rounded-l-xl focus:outline-none z-10"
        >
          <span>Zur Auswertung</span>
          <img
            src={asset(`icons/icon-next.png`)}
            alt="The Section Icon"
            className="max-h-6 ml-4"
          ></img>{" "}
        </button>
      )}
      {store.isInResultMode && !store.showConversionDialog && (
        <button
          onClick={() => {
            store.setShowConversionDialog(true);
          }}
          className="print:hidden absolute flex flex-row bottom-16 md:bottom-4 right-0 items-center bg-green-600 hover:shadow-md hover:bg-green-700 text-white font-semibold py-4 px-4 rounded-l-xl focus:outline-none z-10"
        >
          <span>Bericht per Email erhalten</span>
          <img
            src={asset(`icons/icon-next.png`)}
            alt="The Section Icon"
            className="max-h-6 ml-4"
          ></img>{" "}
        </button>
      )}
      <div className="space-y-4">
        {store.isInResultMode && store.isInPrintMode && <FrontPage />}
        {store.isInResultMode && <ResultsSection />}
        {!store.isInResultMode && (
          <>
            <h1 className="text-xl text-black font-semibold mt-8">
              Wie viel CO2 stoße ich aus?
            </h1>
            <p className="text-md text-gray-600 md:mt-8">
              Berechnen Sie mit unserem CO2 Rechner Ihren individuellen
              Fußabdruck! Die Voreinstellungen ergeben sich aus den Mittelwerten
              der bereits getätigten Berechnungen.
            </p>
            <p className="text-md text-gray-600 md:mt-8">
              Wir zeigen Ihnen Möglichkeiten auf, wie und wo Sie diesen
              verringern können.
            </p>
          </>
        )}
        {store.sections.map((sectionKey) => {
          const section = store.data[sectionKey];
          const ref = useRef<HTMLDivElement>(null);
          const hints = useEffect(() => {
            store.setSectionRef(sectionKey, ref);
          }, []);
          return (
            <div>
              <div
                ref={ref}
                className="z-10 flex flex-row flex-wrap space-x-4 py-4 md:pt-8 md:my-8 mx-2 items-center border-b-4 border-gray-200"
              >
                <img
                  src={asset(`icons/${section.icon}.svg`)}
                  alt="The Section Icon"
                  className="max-h-6"
                ></img>
                <h1 className="text-xl text-black font-semibold">
                  {section.title}
                </h1>
              </div>
              {!store.isInResultMode && section.values && (
                <ul className="divide-y-2 m-2">
                  {Object.keys(section.values).map((key) => {
                    const value = section.values![key];
                    return isSingleInput(value) ? (
                      isSliderInput((value as ValueModel).input) ? (
                        <SliderInput
                          value={value as ValueModel}
                          onValueChanged={(value) =>
                            store.updateData(sectionKey, key, value)
                          }
                          onToggleChanged={(value) =>
                            store.toggleItem(sectionKey, key, value)
                          }
                        ></SliderInput>
                      ) : (
                        <MultipleChoiceInput
                          value={value as ValueModel}
                          onValueChanged={(value) =>
                            store.updateData(sectionKey, key, value)
                          }
                        ></MultipleChoiceInput>
                      )
                    ) : (
                      <ValueGroupInput
                        groupKey={key}
                        value={value}
                        onValueChanged={(key, subkey, value) =>
                          store.updateData(sectionKey, key, value, subkey)
                        }
                        onToggleChanged={(key, subkey, value) =>
                          store.toggleItem(sectionKey, key, value, subkey)
                        }
                        onChipChanged={(groupKey, key, checked) =>
                          store.enableValue(sectionKey, groupKey, key, checked)
                        }
                      ></ValueGroupInput>
                    );
                  })}
                </ul>
              )}
              {sectionKey === "public" && (
                <p className="text-md text-gray-600">
                  Unter öffentlichen Emissionen versteht man Ausstöße, die durch
                  die Infrastruktur und öffentliche Einrichtungen in Deutschland
                  verursacht werden: Straßenbau, Polizei, Schulen,
                  Wasserversorgung, Abfallwirtschaft, usw. tragen jährlich ca.
                  1,10 Tonnen CO2 pro Person bei und sind für jeden gleich.
                </p>
              )}
              {store.isInResultMode && <ResultsBody sectionKey={sectionKey} />}
            </div>
          );
        })}
      </div>
      {store.showConversionDialog && (
        <div
          onClick={() => store.setShowConversionDialog()}
          className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80"
        >
          <ConversionDialog onDismiss={() => store.setShowConversionDialog()} />
        </div>
      )}
    </div>
  );
});
