import { observer } from "mobx-react-lite";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react"; // importing FunctionComponent
import { SliderInputModel, ValueModel } from "../../types";
import { Hint } from "../Hint";
import { Toggle } from "../Toggle";
import { H2 } from "../typo";
var classNames = require("classnames");

type Props = {
  value: ValueModel;
  onValueChanged: (value: number) => void;
  onToggleChanged: (value: boolean) => void;
  isCondensed?: boolean;
};

const handle = (unit: String | undefined) => (props: any) => {
  const { value, dragging, index, offset, ...restProps } = props;
  return (
    <div
      className={classNames(
        "select-none flex-row flex-nowrap absolute truncate bg-green-600 p-2 rounded-md text-white px-4 font-semibold mx-4"
      )}
      style={{
        left: `${offset}%`,
        transform: `translateX(-50px) translateY(-49%)`,
      }}
    >
      {`${value}${unit ? ` ${unit}` : ""}`}
    </div>
  );
};

export const SliderInput: React.FC<Props> = observer(
  ({ value, onValueChanged, onToggleChanged, isCondensed }) => {
    const input: SliderInputModel = value.input as SliderInputModel;

    return (
      <div className={classNames("relative py-4", { "py-8": !isCondensed })}>
        <div className="flex flex-row justify-between items-center">
          <H2>{`${isCondensed ? value.subtitle : value.title}`}</H2>
          {/* ${value.unit ? `(in ${value.unit})` : ""} */}
          {value.hasElectricityOption && (
            <div className="row items-center">
              <span className="mr-2">Elektrisch </span>
              <Toggle
                onChanged={onToggleChanged}
                isEnabled={value.isElectric ?? false}
              ></Toggle>
            </div>
          )}
          {value.hint && <Hint hint={value.hint}></Hint>}
        </div>
        {!isCondensed && value.subtitle && (
          <p className="mb-4 text-sm">{value.subtitle}</p>
        )}
        <div className="h-8"></div>
        <div
          className={classNames("px-4 mx-2 ", {
            "mr-12": value.unit?.length ?? 0 > 2,
          })}
        >
          {
            <Slider
              min={(input as SliderInputModel).min}
              max={input.max}
              className="cursor-pointer ml-2"
              onChange={onValueChanged}
              handle={handle(value.unit)}
              value={value.value}
              trackStyle={{ backgroundColor: "#49815a" }}
            />
          }
        </div>
      </div>
    );
  }
);
