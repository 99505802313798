import React, { ReactNode } from "react";
var classNames = require("classnames");

interface Props {
  children: ReactNode;
  disabled?: boolean;
  className?: string
}

export const H1: React.FC<Props> = ({ className, children, disabled }) => (
  <h1
    className={classNames(className, "text-xl font-semibold", {
      "text-gray-400": disabled,
      "text-gray-800": !disabled,
    })}
  >
    {children}
  </h1>
);
